import { currentDateTime } from 'utils';
import { PICTURE_SERVICE_OFFER } from 'config/constants';
import Ticket from './Ticket';
import ServiceOfferDetails from './ServiceOfferDetails';
import PictureMetaData from './PictureMetaData';

class Picture {
  id: number;

  picture_index: number;

  url: string;

  thumbnail_url: string;

  original_url: string;

  distrib_url: string;

  shooting_id: number;

  watermarked_url: string;

  show_watermark: boolean;

  broker_unlocked_picture: string;

  tickets: Ticket[] = [];

  service_offer?: ServiceOfferDetails = new ServiceOfferDetails();

  was_downloaded: boolean;

  is_purchased: boolean;

  lightbox_URL: string;

  raw_thumbnail_url: string;

  raw_url: string;

  sent_to_esoft: boolean;

  size: number;

  metadata: PictureMetaData = new PictureMetaData();

  public: boolean;

  included_in_video: boolean;

  service_offer_id: number;

  is_undedited: boolean;

  is_required: boolean;

  constructor(data: object = {}) {
    Object.assign(this, data);
    if (this.service_offer) {
      this.service_offer = new ServiceOfferDetails(this.service_offer);
    }
    if (this.metadata) {
      this.metadata = new PictureMetaData(this.metadata);
    }
    this.lightbox_URL = this.show_watermark ? this.watermarked_url : this.distrib_url;
    if (!this.thumbnail_url && !this.distrib_url && this.raw_thumbnail_url) {
      this.is_undedited = true;
      this.lightbox_URL = this.raw_url;
    } else {
      this.is_undedited = false;
    }
  }

  static PICTURE_TYPE = {
    STANDARD: 'standard',
    BLUE_HOUR: 'blue_hour',
    DRONE: 'drone',
  };

  static STANDARD_TYPE = [
    PICTURE_SERVICE_OFFER.applicationTraditionalPic,
    PICTURE_SERVICE_OFFER.carteTraditionalPic,
    PICTURE_SERVICE_OFFER.plateformeTraditionalPic,
  ];

  static BLUE_HOUR_TYPE = [
    PICTURE_SERVICE_OFFER.applicationBlueHourPic,
    PICTURE_SERVICE_OFFER.carteBlueHourPic,
    PICTURE_SERVICE_OFFER.plateformeBlueHourPic,
  ];

  static DRONE_TYPE = [
    PICTURE_SERVICE_OFFER.applicationDronePic,
    PICTURE_SERVICE_OFFER.carteDronePic,
    PICTURE_SERVICE_OFFER.plateformeDronePic,
  ];

  get thumbnailURL(): string {
    return `${this.thumbnail_url}`;
  }

  get distribURL(): string {
    return `${this.distrib_url}`;
  }

  get watermarkedURL(): string {
    return `${this.watermarked_url}`;
  }

  get isUndedited(): boolean {
    return this.is_undedited;
  }

  get lightboxURL(): string {
    return `${this.lightbox_URL}`;
  }

  get type(): string {
    if (Picture.STANDARD_TYPE.includes(this.service_offer_id)) {
      return 'standard';
    }
    if (Picture.BLUE_HOUR_TYPE.includes(this.service_offer_id)) {
      return 'blue_hour';
    }
    if (Picture.DRONE_TYPE.includes(this.service_offer_id)) {
      return 'drone';
    }
    return 'standard';
  }

  get allUrls(): string[] {
    const urls = [this.thumbnailURL, this.distribURL, this.watermarkedURL];
    const filteredUrls = urls.filter((url) => {
      return url !== undefined && url !== null && url !== '';
    });
    return filteredUrls;
  }

  set setLightBoxURL(url: string) {
    this.lightbox_URL = url;
  }
}

export default Picture;
