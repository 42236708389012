import { images } from 'assets/images';

const stagingExamples = images.aiStagingExamples;

export const getTitle = (item: any, language: string) => {
  return language === 'fr' ? item.title_fr : item.title;
};

export const roomStyles = {
  modern: {
    title: 'Modern',
    title_fr: 'Moderne',
    image: stagingExamples.modern,
  },
  scandinavian: {
    title: 'Scandinavian',
    title_fr: 'Scandinave',
    image: stagingExamples.scandinavian,
  },
  industrial: {
    title: 'Industrial',
    title_fr: 'Industriel',
    image: stagingExamples.industrial,
  },
  midcentury: {
    title: 'Mid century',
    title_fr: 'Milieu du siècle',
    image: stagingExamples.midcentury,
  },
  luxury: {
    title: 'Luxury',
    title_fr: 'Luxueux',
    image: stagingExamples.luxury,
  },
  farmhouse: {
    title: 'Farmhouse',
    title_fr: 'Ferme',
    image: stagingExamples.farmhouse,
  },
  coastal: {
    title: 'Coastal',
    title_fr: 'Côtier',
    image: stagingExamples.coastal,
  },
  standard: {
    title: 'Standard',
    title_fr: 'Standard',
    image: stagingExamples.standard,
  },
};

export const roomTypes = [
  {
    key: 'living',
    title: 'Living Room',
    title_fr: 'Salon',
    icon: images.livingRoomIcon,
    standard:
      'https://storage.googleapis.com/cdn.hauslifenetwork.com/ai_vs_examples/living_room/standard.jpg',
    modern:
      'https://storage.googleapis.com/cdn.hauslifenetwork.com/ai_vs_examples/living_room/modern.jpg',
    scandinavian:
      'https://storage.googleapis.com/cdn.hauslifenetwork.com/ai_vs_examples/living_room/scandinavian.jpg',
    industrial:
      'https://storage.googleapis.com/cdn.hauslifenetwork.com/ai_vs_examples/living_room/industrial.jpg',
    midcentury:
      'https://storage.googleapis.com/cdn.hauslifenetwork.com/ai_vs_examples/living_room/midcentury.jpg',
    luxury:
      'https://storage.googleapis.com/cdn.hauslifenetwork.com/ai_vs_examples/living_room/luxury.jpg',
    farmhouse:
      'https://storage.googleapis.com/cdn.hauslifenetwork.com/ai_vs_examples/living_room/farmhouse.jpg',
    coastal:
      'https://storage.googleapis.com/cdn.hauslifenetwork.com/ai_vs_examples/living_room/coastal.jpg',
  },
  {
    key: 'bed',
    title: 'Bedroom',
    title_fr: 'Chambre',
    icon: images.bedroomIcon,
    standard:
      'https://storage.googleapis.com/cdn.hauslifenetwork.com/ai_vs_examples/bedroom/standard.jpg',
    modern:
      'https://storage.googleapis.com/cdn.hauslifenetwork.com/ai_vs_examples/bedroom/modern.jpg',
    scandinavian:
      'https://storage.googleapis.com/cdn.hauslifenetwork.com/ai_vs_examples/bedroom/scandinavian.jpg',
    industrial:
      'https://storage.googleapis.com/cdn.hauslifenetwork.com/ai_vs_examples/bedroom/industrial.jpg',
    midcentury:
      'https://storage.googleapis.com/cdn.hauslifenetwork.com/ai_vs_examples/bedroom/midcentury.jpg',
    luxury:
      'https://storage.googleapis.com/cdn.hauslifenetwork.com/ai_vs_examples/bedroom/luxury.jpg',
    farmhouse:
      'https://storage.googleapis.com/cdn.hauslifenetwork.com/ai_vs_examples/bedroom/farmhouse.jpg',
    coastal:
      'https://storage.googleapis.com/cdn.hauslifenetwork.com/ai_vs_examples/bedroom/coastal.jpg',
  },
  {
    key: 'kitchen',
    title: 'Kitchen',
    title_fr: 'Cuisine',
    icon: images.kitchenRoomIcon,
    standard:
      'https://storage.googleapis.com/cdn.hauslifenetwork.com/ai_vs_examples/kitchen/standard.jpg ',
    modern:
      'https://storage.googleapis.com/cdn.hauslifenetwork.com/ai_vs_examples/kitchen/modern.jpg',
    scandinavian:
      'https://storage.googleapis.com/cdn.hauslifenetwork.com/ai_vs_examples/kitchen/scandinavian.jpg',
    industrial:
      'https://storage.googleapis.com/cdn.hauslifenetwork.com/ai_vs_examples/kitchen/industrial.jpg',
    midcentury:
      'https://storage.googleapis.com/cdn.hauslifenetwork.com/ai_vs_examples/kitchen/midcentury.jpg',
    luxury:
      'https://storage.googleapis.com/cdn.hauslifenetwork.com/ai_vs_examples/kitchen/luxury.jpeg',
    farmhouse:
      'https://storage.googleapis.com/cdn.hauslifenetwork.com/ai_vs_examples/kitchen/farmhouse.jpg',
    coastal:
      'https://storage.googleapis.com/cdn.hauslifenetwork.com/ai_vs_examples/kitchen/coastal.jpg',
  },
  {
    key: 'dining',
    title: 'Dining Room',
    title_fr: 'Salle à manger',
    icon: images.diningRoomIcon,
    standard:
      'https://storage.googleapis.com/cdn.hauslifenetwork.com/ai_vs_examples/dining_room/standard.jpg',
    modern:
      'https://storage.googleapis.com/cdn.hauslifenetwork.com/ai_vs_examples/dining_room/modern.jpg',
    scandinavian:
      'https://storage.googleapis.com/cdn.hauslifenetwork.com/ai_vs_examples/dining_room/scandinavian.jpg',
    industrial:
      'https://storage.googleapis.com/cdn.hauslifenetwork.com/ai_vs_examples/dining_room/industrial.jpg',
    midcentury:
      'https://storage.googleapis.com/cdn.hauslifenetwork.com/ai_vs_examples/dining_room/midcentury.jpg',
    luxury:
      'https://storage.googleapis.com/cdn.hauslifenetwork.com/ai_vs_examples/dining_room/luxury.jpg',
    farmhouse:
      'https://storage.googleapis.com/cdn.hauslifenetwork.com/ai_vs_examples/dining_room/farmhouse.jpg',
    coastal:
      'https://storage.googleapis.com/cdn.hauslifenetwork.com/ai_vs_examples/dining_room/coastal.jpg',
  },
  {
    key: 'home_office',
    title: 'Home Office',
    title_fr: 'Bureau',
    icon: images.homeOfficeIcon,
    standard:
      'https://storage.googleapis.com/cdn.hauslifenetwork.com/ai_vs_examples/home_office/standard.jpg',
    modern:
      'https://storage.googleapis.com/cdn.hauslifenetwork.com/ai_vs_examples/home_office/modern.jpg',
    scandinavian:
      'https://storage.googleapis.com/cdn.hauslifenetwork.com/ai_vs_examples/home_office/scandinavian.jpg',
    industrial:
      'https://storage.googleapis.com/cdn.hauslifenetwork.com/ai_vs_examples/home_office/industrial.jpg',
    midcentury:
      'https://storage.googleapis.com/cdn.hauslifenetwork.com/ai_vs_examples/home_office/midcentury.jpg',
    luxury:
      'https://storage.googleapis.com/cdn.hauslifenetwork.com/ai_vs_examples/home_office/luxury.jpg',
    farmhouse:
      'https://storage.googleapis.com/cdn.hauslifenetwork.com/ai_vs_examples/home_office/farmhouse.jpg',
    coastal:
      'https://storage.googleapis.com/cdn.hauslifenetwork.com/ai_vs_examples/home_office/coastal.jpg',
  },
];

export const getRoomType = (key: string, language: string) => {
  const roomType = roomTypes.find((room) => {
    return room.key === key;
  });
  return roomType ? getTitle(roomType, language) : '';
};

export const getRoomStyleImage = (roomType: string, roomStyle: string) => {
  const foundRoomType = roomTypes.find((room) => {
    return room.key === roomType;
  });

  if (foundRoomType) {
    const imageUrl = foundRoomType[roomStyle];

    if (imageUrl) {
      return imageUrl;
    }
    return roomStyles[roomStyle].image;
  }
  return roomStyles[roomStyle].image;
};
