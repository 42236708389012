export const isEmailValid = (email: string) => {
  const re =
    /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email?.toLowerCase().trim() || '');
};

export const isPasswordValid = (password: string) => {
  return password?.length >= 8 || false;
};

export const getPhoneNumberWithFormatting = (phone_number: string) => {
  if (phone_number.length === 11) {
    return phone_number.replace(/(\d{1})(\d{3})(\d{3})(\d{3})/, '$1-$2-$3-$4');
  }

  return phone_number.replace(/(\d{3})(\d{3})(\d{3})/, '$1-$2-$3');
};
