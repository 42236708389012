export const COLOR = {
  TURQUOISE: '#51e8fc',
  ULTRA_LIGHT_BLUE: '#ACE1FA',
  LIGHT_BLUE: '#00bdf2',
  LIGHT_BLUE_BG: '#E4F9FF',
  BLUE: '#007DC5',
  BLUE2: '#00BDF2',
  DARK_BLUE: '#034EA2',
  VERY_DARK_BLUE: '#0325a2',
  RED: '#db3236',
  GREEN: '#0FA958',
  ORANGE: '#ff7b0e',
  LIGHT_ORANGE: '#FF9900',
  GREY: '#bcbbc1',
  LIGHT_GREY: '#ebebeb',
  ULTRA_LIGHT_GREY: '#f3f3f3',
  DARK_GREY: '#7f7e82',
  BROWN: '#330000',
  GREEN_DARTMOUTH: '#306B34',
  BLACK: '#000',
  WHITE: '#fff',
  ULTRA_DARK_BLUE: '#0b1532',
  DELETE_RED: '#fc5249',
  LIGHT_RED: '#ff8080',
  RED_TEXT: '#FA5B38',
  LIGHT_RED_CONTENT: '#FFECED',
  TRANSPARENT_BLACK: 'rgba(0,0,0,0.2)',
  SOCIAL_MEDIA_GREY: '#5a5a5a',
  LIGHT_YELLOW: '#FFF8E3',
  YELLOW: '#FFBE00',
  DARK_YELLOW: '#FFA000',
  CHARCOAL: '#333333',
  LIGHT_GREEN: '#C9FFD2',
};

export const PAGE_SIZE = 10;
export const GRID_PAGE_SIZE = 18;

export const MIN_VISIT_COUNT = 15;
export const MAX_FILTER_PRICE = 5000000;
export const TEMPLATES = {
  SIMPLE: 'simple',
  BROKER_HIGHLIGHT: 'broker_highlight',
  BIG_BANNER: 'big_banner',
  LISTINGS_BANNER: 'listings_banner',
};
export const MODAL = {
  SHOOTING_EDIT: 'SHOOTING_EDIT',
  SHOOTING_CANCEL: 'SHOOTING_CANCEL',
  DRAFT_SHOOTING_DELETE: 'DRAFT_SHOOTING_DELETE',
  DRAFT_SHOOTING_SAVE: 'DRAFT_SHOOTING_SAVE',
  TRANSER_REQUEST: 'TRANSER_REQUEST',
  LOGIN: 'LOGIN',
  VIRTUAL_STAGING: 'VIRTUAL_STAGING',
  VIRTUAL_STAGING_SECOND_STEP: 'VIRTUAL_STAGING_SECOND_STEP',
  VIRTUAL_STAGING_REVIEW: 'VIRTUAL_STAGING_REVIEW',
  FLOORPLAN: 'FLOORPLAN',
  ADDITIONAL_PICTURES: 'ADDITIONAL_PICTURES',
  PICTURE_SWAP: 'PICTURE_SWAP',
  RECOMMENDATION: 'RECOMMENDATION',
  ADD_NEW_FAVORITE_LISTING: 'ADD_NEW_FAVORITE_LISTING',
  REMOVE_FAVORITE_LISTING: 'REMOVE_FAVORITE_LISTING',
  SHARE: 'SHARE',
  SLIDESHOW: 'SLIDESHOW',
  SLIDESHOW_TEMPLATE: 'SLIDESHOW_TEMPLATE',
  SLIDESHOW_REVIEW: 'SLIDESHOW_REVIEW',
  AI_SERVICES: 'AI_SERVICES',
  GENERATED_PHOTOS: 'GENERATED_PHOTOS',
  SCHEDULER_MODAL: 'SCHEDULER_MODAL',
};
export const SLIDESHOWMODALSTEP = {
  TEMPLATES: 0,
  PICTURES: 1,
  SUMMARY: 2,
};
export const SERVICEMODALSTEP = {
  EXAMPLE: 0,
  OPTION: 1,
  REVIEW: 2,
  BLUEHOUR: 3,
};
export const FLOORPLANMODALSTEP = {
  EXAMPLE: 0,
  OPTION: 1,
  REVIEW: 2,
};
export const PICTURESWAPSTEP = {
  EXCHANGE: 0,
  EXCHANGEWITH: 1,
  CONFIRM: 2,
};
export const ADD_NEW_FAVORITE_LISTING_STEP = {
  OPTIONS: 0,
  SEARCH_LISTING: 1,
  DEMO_LISTINGS: 2,
  CONFIRM: 3,
};
export const SERVICE_OFFER_NAME = {
  VIRTUAL_BLUE_HOUR: 'Virtual Blue Hour',
  VIRTUAL_RENOVATION: 'Virtual Renovation',
};
export const SIDEBAR = {
  SHOOTING_DETAIL: 'SHOOTING_DETAIL',
};

export const ALERT = {
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
  SUCCESS: 'success',
};

export const PLACE_OPTION_TYPES = {
  GOOGLE: 'GOOGLE',
  POST_CANADA: 'POST_CANADA',
  EXPAND_SEARCH: 'EXPAND_SEARCH',
};

export const DATEFORMAT_MY = 'MM/yyyy';

export const SHOOTING_STEPS = {
  LOCATION: 0,
  PROPERTY: 1,
  PACKAGE: 2,
  SERVICE: 3,
  CLIENT_INFO: 4,
  REVIEW: 5,
  SELECT_DATETIME: 6,
};

export const PLANS = {
  FREE: 0,
  MONTHLY: 1,
  YEARLY: 2,
  TERM: {
    MONTHLY: 0,
    QUARTERLY: 1,
    YEARLY: 2,
  },
  SUBSCRIPTION_PORTAL_LINK: 'https://subscriptions.zoho.com/portal/hausvalet',
};

export const GPT = {
  TEXT_LENGTH: {
    SHORT: 'short',
    MEDIUM: 'medium',
    LONG: 'long',
  },
  MAX_INPUT_LENGTH: 100, // 100 Characters
  MAX_PROMPT_TAGS_CHARACTERS: 1400, // 1400 Characters (about 400 tokens)
  DAILY_TOKEN_LIMIT: 10000, // 10000 Tokens
};

export const TEXTINPUT_TYPE = {
  TEXT: 0,
  DROPDOWN: 1,
  NUMBER: 2,
};

export const PROFILE_ITEMS = {
  MOBILE: 'phone_number',
  OFFICE: 'office_phone_number',
  TITLE: 'title_num',
  CUSTOM_TITLE: 'custom_title',
  BANNER_ID: 'banner_id',
  FRANCHISE_ID: 'franchise_id',
  CENTRIS_CODE: 'broker_code',
  QACIQ_CODE: 'oaciq_code',
  FBPIXEL_ID: 'pixel_id',
  PASSWORD: 'password',
};
export const USER_ROLE = {
  SUPERADMIN: 1,
  ADMIN: 2,
  BROKER: 3,
};
export const STATE = {
  NOT_STARTED: {
    KEY: 0,
    NAME: 'notStarted',
  },
  ORDERED: {
    KEY: 1,
    NAME: 'ordered',
  },
  ONGOING: {
    KEY: 2,
    NAME: 'ongoing',
  },
  COMPLETED: {
    KEY: 3,
    NAME: 'completed',
  },
  DELIVERED: {
    KEY: 4,
    NAME: 'delivered',
  },
  CANCELLED: {
    KEY: 5,
    NAME: 'cancelled',
  },
};

export const SERVICE_OFFER_ACTION = {
  CANCEL: 'cancel',
  ASSIGN: 'assign',
  ADDNOTE: 'addNote',
  CREATE: 'create',
  SETTONOTSTARTED: 'setToNotStarted',
  SETTOCOMPLETED: 'setToCompleted',
  SETTODELIVERED: 'setToDelivered',
  SETTOONGOING: 'setToOnGoing',
  SETTOORDERED: 'setToOrdered',
  ADDSERVICEOFFER: 'addServiceOffer',
};

export const VIRTUAL_SERVICES = {
  FURNITURE_WEBSITE: 'https://www.virtualfurniture.io/?c=ge3wZqDn5X',
  CONTACT_LINK: 'https://hausvalet.ca/contact/',
};

export const GOOGLE_MAP = {
  GOOGLE_MAP_URL:
    'https://maps.googleapis.com/maps/api/js?key=AIzaSyCBlV8_octv2fJn5m6dAPJPn0UozRFm_q8&v=3.exp',
  DEFAULT_GOOGLE_MARKER_ICON: 'http://maps.google.com/mapfiles/ms/micons/man.png',
  SHOOTING_MARKER_ICON:
    'https://storage.googleapis.com/cdn.hauslifenetwork.com/logo/HausValet%20_fav.png',
};

export const ADMIN_PANEL_URL = 'https://admin.hausvalet.ca';

export const INVOICE_URL_PREFIX = 'https://books.zoho.com/app#/invoices/';

export const GST_QST_RATE = 0.14975;
