import _, { method } from 'lodash';
import $, { data } from 'jquery';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import axios from 'axios';
import { getAPIURL, CDN_URL, PICTURE_RESOLUTION_TYPE } from 'config/constants';
import {
  getFilesHashMapFromCloud,
  setPictureURL,
  preparePictureForDelivery,
  createFileAnalysisJob,
  create3DProcessingJob,
  getFilesNameAndSizeGCP,
  setHdrsProcUploaded,
} from 'apis';
import SparkMD5 from 'spark-md5';
import {
  getDeliveriesFromTokenZip,
  getDetailedFloorplanFromTokenZip,
  markDropboxPictureAsDownloaded,
  fetchDownloadLink,
  createFileCompressorJob,
  generateDownloadUrlForPostProd,
  setFileMetadata,
} from './urls';

const zipUtility = new JSZip();
export const cancelTokenMap = new Map();
export function convertSizeToHuman(size: number) {
  const mb = size / 1024 / 1024;

  if (mb > 1000) {
    return `${(mb / 1024).toFixed(2)} GB`;
  }

  return `${mb.toFixed(2)} MB`;
}

export function savePhotosToDropbox(files: any[], callback: Function, token: string) {
  const filesURL = files.map((file) => {
    return file.url;
  });
  const options = {
    files,
    success() {
      markDropboxPictureAsDownloaded(token, filesURL);
      callback(false);
    },
    progress() {
      callback(true);
    },
    cancel() {},
    error(errorMessage: any) {
      console.log(errorMessage);
    },
  };

  if (window.Dropbox) {
    window.Dropbox.save(options);
  }
}

export async function downloadPictures(token: string, userRole: string) {
  window.location.href = getDeliveriesFromTokenZip(token, [], userRole);
}

async function getFileAsBlob(url: string) {
  return axios
    .get(url, {
      method: 'GET',
      responseType: 'blob',
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return console.log(error);
    });
}

export function fileNameFromURL(url: string) {
  const segments = new URL(url).pathname.split('/');
  return segments.pop() || segments.pop();
}

export async function downloadDetailedFloorplan(token: string, prettyName: string) {
  const files = await getDetailedFloorplanFromTokenZip(token);

  if (files !== undefined && files !== null && files.medias !== null && files.medias.length > 0) {
    const ENG_FOLDER = zipUtility.folder('ENG');
    const FR_FOLDER = zipUtility.folder('FR');
    const blobs = files.medias.map(async (file: any) => {
      const floorplan_media = await getFileAsBlob(file.url);
      if (file.language === 'ENG') {
        ENG_FOLDER.file(file.url.split('/').pop(), floorplan_media, { binary: true });
      } else {
        FR_FOLDER.file(file.url.split('/').pop(), floorplan_media, { binary: true });
      }
      return file;
    });
    Promise.all(blobs).then(async () => {
      await zipUtility.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(content, `${prettyName}.zip`);
      });
    });
    return true;
  }
  return false;
}

export async function largeFileDownloadPostProd(token: string, downloadData: any) {
  const response = await createFileCompressorJob(downloadData, token);
  return response;
}

export async function fileDownloadPostProd(
  token: string,
  listing_id: number,
  download_type: string,
  shooting_id: number,
) {
  const download_url = await generateDownloadUrlForPostProd(token, listing_id, shooting_id);
  window.location.href = `${getAPIURL()}${download_url}&download_type=${download_type}`;
}

export async function downloadVideos(
  filePath: string,
  token: string,
  videoIsDownloading: Function,
) {
  const downloadUrl = await fetchDownloadLink(filePath, token);
  const response = await axios.get(downloadUrl, {
    responseType: 'blob',
  });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filePath.split('/').pop());
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  videoIsDownloading(false);
}

export async function downloadBlobFile(
  url: string,
  fileName: string,
  headers: object = {},
  callback: Function = _.noop,
) {
  $.ajax({
    url,
    headers: { ...headers },
    cache: false,
    xhr() {
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 2) {
          xhr.responseType = xhr.status === 200 ? 'blob' : 'text';
        }
      };
      xhr.onprogress = (evt) => {
        const size = convertSizeToHuman(evt.loaded);
        callback(`downloading... ${size}`);
      };
      xhr.onloadend = () => {
        callback('done');
      };
      return xhr;
    },
    success(data) {
      const blob = new Blob([data]);
      const isIE = false || document.documentMode;

      if (isIE) {
        window.navigator.msSaveBlob(blob, fileName);
      } else {
        const URL = window.URL || window.webkitURL;
        const href = URL.createObjectURL(blob);

        const a = $("<a id='download'/>");
        a.attr('download', fileName);
        a.attr('href', href);

        $('body').append(a);
        a[0].click();
        $('body').remove('#download');
      }
    },
  });
}

export async function downloadSelectedPictures(
  token: string,
  pictureIds: Array<Number>,
  pictures: Array<any>,
  prettyName: string,
  userRole: string,
) {
  /*  TODO mark single pic as downloaded, and allow the pic to be downloaded in non zip format
    if (pictureIds.length === 1) {
    pictures = pictures.filter((picture) => {
      return picture.id === pictureIds[0];
    });
    const url = pictures[0].distrib_url;
    downloadBlobFile(url, `${prettyName}-1.jpg`);
  } else {
    window.location.href = getDeliveriesFromTokenZip(token, pictureIds, userRole);
  } */
  window.location.href = getDeliveriesFromTokenZip(token, pictureIds, userRole);
}

export async function uploadFileWithProgress(file: File, url: string, callback: Function) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.upload.addEventListener(
      'progress',
      (e) => {
        if (e.lengthComputable) {
          callback(file.name, Math.round((e.loaded * 100) / e.total));
        }
      },
      false,
    );

    xhr.upload.addEventListener(
      'load',
      () => {
        callback(file.name, 100);
      },
      false,
    );

    xhr.addEventListener('load', () => {
      if (xhr.status !== 200) {
        reject(new Error('Problem uploading file.'));
      } else {
        resolve(true);
      }
    });

    xhr.onerror = (error) => {
      console.log(error);
      reject(error);
    };

    xhr.open('PUT', url);
    xhr.setRequestHeader('content-type', file.type);
    xhr.send(file);
  });
}

export async function multipleFileUpload(
  file: File,
  url: string,
  onProgress: Function,
  fileFailedToUpload: Function,
  setMediaUrl: Function,
) {
  try {
    const options = {
      headers: {
        'Content-Type': file.type,
      },
    };
    const response = await axios.put(url, file, options);
    if (response && response.status === 200) {
      onProgress();
      await setMediaUrl(file, url);
    } else {
      fileFailedToUpload(file, url);
      console.error('Error uploading file with url:', response.statusText);
    }
  } catch (error) {
    console.log('[multipleFileUpload]', error);
  }
}

export async function convertFileToArrayBuffer(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onerror = function onerror(ev) {
      reject(ev.target.error);
    };

    reader.onload = function onload(ev) {
      resolve(ev.target.result);
    };

    reader.readAsArrayBuffer(file);
  });
}

export async function filterFilesThatAreSameInCloud(
  files: any,
  uploadPathPrefix: string,
  token: string,
) {
  try {
    const cloudFiles = await getFilesHashMapFromCloud(uploadPathPrefix, token);
    if (!cloudFiles && cloudFiles.length === 0) {
      return files;
    }
    const filteredCloudFiles = [];
    const duplicatedCloudFiles = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i].file ? files[i].file : files[i];
      const fileBuffer: any = await convertFileToArrayBuffer(file);
      const fileHash = SparkMD5.ArrayBuffer.hash(fileBuffer);
      const fileInCloud = cloudFiles.find((cloudFileHash: any) => {
        return cloudFileHash === fileHash;
      });
      if (!fileInCloud) {
        filteredCloudFiles.push(files[i]);
      } else {
        duplicatedCloudFiles.push(files[i]);
      }
    }
    return { duplicatedCloudFiles, filteredCloudFiles };
  } catch (error) {
    console.log('[filterFilesThatAreSameInCloud]', error);
  }
  return [];
}

export async function checkIfFileIsSameInCloud(file: any, cloudFilesHash: any) {
  try {
    if (!cloudFilesHash && cloudFilesHash.length === 0) {
      return false;
    }
    const fileBuffer: any = await convertFileToArrayBuffer(file);
    const fileHash = SparkMD5.ArrayBuffer.hash(fileBuffer);
    const fileInCloud = cloudFilesHash.find((cloudFileHash: any) => {
      return cloudFileHash === fileHash;
    });
    if (!fileInCloud) {
      return false;
    }
    return true;
  } catch (error) {
    console.log('[filterFilesThatAreSameInCloud]', error);
  }
}

export async function saveMediaLinkToDB(
  file: any,
  shooting: any,
  fileFailedToUpload: Function,
  token: string,
) {
  const pictureUrl = `${CDN_URL + file.uploadPath}`;
  const picture_index = file.name.substring(
    file.name.lastIndexOf('_') + 1,
    file.name.lastIndexOf('.'),
  );
  const res = await setPictureURL(
    PICTURE_RESOLUTION_TYPE.original,
    shooting.listing_id,
    shooting.id,
    picture_index,
    pictureUrl,
    token,
  );
  if (res.status === 200) {
    await preparePictureForDelivery(
      shooting.listing_id,
      shooting.id,
      pictureUrl,
      shooting.shouldPrioritize,
      token,
    );
  } else {
    fileFailedToUpload(file, shooting.id);
  }
}

export async function uploadShootingFilesToCloud(
  shootings: any,
  onProgress: Function,
  fileFailedToUpload: Function,
  setTotalProgress: Function,
  getFailedFilesCount: Function,
  token: string,
) {
  for (let i = 0; i < shootings.length; i++) {
    const shooting = shootings[i];
    const { files } = shooting;
    const cloudFilesHash = await getFilesHashMapFromCloud(`${shooting.listing_id}/2D`, token);
    for (let j = 0; j < files.length; j++) {
      const file = files[j];
      if (!file) {
        console.error('Invalid file object:', file);
      }
      const fileAlreadyUploaded = await checkIfFileIsSameInCloud(file, cloudFilesHash);
      if (fileAlreadyUploaded) {
        onProgress(100, i, file.name);
        setTotalProgress((prevProgress: any) => {
          return prevProgress + 100;
        });
        continue;
      }
      const url = file.uploadLink;
      try {
        const options = {
          headers: {
            'Content-Type': file.type,
          },
          onUploadProgress: (progressEvent: any) => {
            const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
            return onProgress(progress, i, file.name);
          },
        };
        const response = await axios.put(url, file, options);
        if (response && response.status === 200) {
          await saveMediaLinkToDB(file, shooting, fileFailedToUpload, token);
        } else {
          fileFailedToUpload(file, shooting.id);
          console.error('Error uploading file with url:', response.statusText);
        }
        setTotalProgress((prevProgress: any) => {
          return prevProgress + 100;
        });
      } catch (error) {
        fileFailedToUpload(file, shooting.id);
        console.error('Error uploading file:', error.message);
      }
    }
    const allFilesFailed = files.length === getFailedFilesCount(shooting.id);
    if (allFilesFailed) {
      continue;
    }
    await setHdrsProcUploaded(shooting.id, token);
  }
}
export async function uploadProductionFiles(
  fileInfo: any,
  token: string,
  onProgress: Function,
  onFileUpload: Function,
  fileFailedToUpload: Function,
  cancelTokenCreation: Function,
) {
  const { file, url, isRequired, uploadPath } = fileInfo;

  try {
    if (!file || file === undefined) {
      throw new Error(`Invalid file object: ${file}`);
    }
    const { CancelToken } = axios;
    const options = {
      headers: {
        'Content-Type': file.type || 'application/octet-stream',
      },
      onUploadProgress: (progressEvent: any) => {
        const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        return onProgress(fileInfo, progress);
      },
      cancelToken: new CancelToken((cancelation) => {
        const cancel = cancelation;
        cancelTokenCreation(file, cancel);
      }),
    };
    const response = await axios.put(url, file, options);
    if (response && response.status === 200) {
      if (isRequired) {
        const metadata = {
          isRequired: true,
        };
        await setFileMetadata(token, uploadPath, metadata, 'injector');
      }
    } else {
      fileFailedToUpload(fileInfo);
      console.error('Error uploading file with url:', response.statusText);
    }
  } catch (error) {
    fileFailedToUpload(fileInfo);
    console.error('Error uploading file:', error);
  } finally {
    onFileUpload();
  }
}

export async function createFileAnalysis(listing_id: number, shooting_id: number, token: string) {
  try {
    const res = await createFileAnalysisJob(listing_id, shooting_id, token);
    return res;
  } catch (error) {
    console.log('[createFileAnalysisJob]', error);
  }
}
export async function create3DJob(listing_id: number, token: string) {
  try {
    const res = await create3DProcessingJob(listing_id, token);
    return res;
  } catch (error) {
    console.log('[create3DJob]', error);
  }
}

export async function getFilesNameAndSize(listing_id: number, shooting_id: number, token: string) {
  try {
    const res = await getFilesNameAndSizeGCP(listing_id, shooting_id, token);
    if (res.status !== 200) {
      return null;
    }
    return res.filesInfos;
  } catch (error) {
    console.log('[filterFilesThatAreSameInCloud]', error);
  }
}

export function checkIf3D(fileName: string) {
  if (fileName.endsWith('.dat') || fileName.endsWith('.prj') || fileName.startsWith('origin')) {
    return true;
  }
  return false;
}

export async function download3DStereo(filePath: string, token: string) {
  const downloadUrl = await fetchDownloadLink(filePath, token);
  const response = await axios.get(downloadUrl, {
    responseType: 'blob',
  });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filePath.split('/').pop());
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export async function downloadSingleFile(file_url: string) {
  const response = await axios.get(file_url, {
    responseType: 'blob',
  });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', file_url.split('/').pop());
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
