import { PORTFOLIO_IMAGES } from 'config/constants';

import logo from './logo.svg';
import logo_horizontal from './logo_horizontal.svg';
import logoK from './logo_k.svg';
import logoTitle from './logo_title.svg';
import logoTitleWhite from './logo_title_white.svg';
import maskGroup from './mask_group.svg';
import maskGroup1 from './mask_group1.svg';
import dropbox from './dropbox.svg';
import download from './download.svg';
import virtualStaging from './HV_Icones_Staging.svg';
import virtualStagingBg from './HV_Icones_Staging-virtuel.png';
import floorplan from './HV_Icones_Plan.svg';
import floorplanMesure from './HV_Icones_Plan-mesure.png';
import deleteIcon from './delete.svg';
import download1 from './download1.svg';
import browser from './browser.svg';
import comment from './comment.svg';
import back from './back.svg';
import prev from './prev.png';
import next from './next.png';
import homeIcon from './home_icon.png';
import starIcon from './star_icon.png';
import inProgressIcon from './inprogress_icon.png';
import blueTimeIcon from './blue_time_icon.png';
import avatar from './avatar.png';
import floorplanExample from './floorplan_example.png';
import edit from './edit.svg';
import edit_shoot from './edit_shooting.svg';
import listing from './listing_icon.svg';
import shooting from './shooting_icon.svg';
import calendar from './calendar_icon.svg';
import broker from './broker_icon.svg';
import broker_white from './broker_icon_white.svg';
import invoice from './invoice_icon.svg';
import invoice_blue from './invoice_blue.svg';
import phone from './phone_icon.svg';
import shootingPackage from './shooting_package_icon.svg';
import drone from './drone.png';
import photographer from './photographer.svg';
import editor from './editor.svg';
import edit_white from './edit_white.svg';
import status from './status.svg';
import user from './user.svg';
import priority from './priority.svg';
import note from './note.svg';
import sd_card from './sd_card.svg';
import add_service_offer from './add_service_offer.svg';
import link from './link.svg';
import ongoing from './ongoing.svg';
import completed from './completed.svg';
import delivered from './delivered.svg';
import cancelled from './cancelled.svg';
import service_offers from './service_offers.svg';
import upload from './upload.svg';
import download_photo from './download_photo.svg';
import not_started from './not_started.svg';
import virtualTour from './virtualTour.png';
import video360 from './video360.png';
import staging from './staging.png';
import floorPlan from './floorplan.png';
import seasonal from './seasonal.png';
import measurements from './measurements.png';
import blue_hour from './blue_hour.png';
import video from './video.png';
import upload_picture from './upload_picture.png';
import video_shoot from './video_shoot.png';
import signature from './signature.png';
import matterport from './matterport.png';
import info from './info.png';
import tick from './tick.png';
import camera from './cameraPhoto.png';
import add_shoot from './add_shoot.svg';
import standard_3d from './Floor_Plan_3D_Standard.jpg';
import advanced_3d from './Floor_Plan_3D_Advanced.jpg';
import address from './address.svg';
import swapArrow from './swapArrow.svg';
import happy from './emojione_smiling-face-with-heart-eyes.png';
import neutral from './emojione_neutral-face.png';
import sad from './emojione_slightly-frowning-face.png';
import add_listing from './add_listing.png';
import empty_listing from './empty_listing.png';
import blue_hour_svg from './blue_hour.svg';
import video_svg from './video.svg';
import drone_svg from './drone.svg';
import no_thumbnail from './no_thumbnail.png';
import video_white from './video_white.svg';
import drone_white from './drone_white.svg';
import blue_hour_white from './blue_hour_white.svg';
import floorplan_white from './HV_Icones_Plan_white.svg';
import virtual_staging_white from './HV_Icones_Staging_white.svg';
import video360_white from './video360_white.png';
import reorder from './reorder.svg';
import upload_white from './upload_white.svg';
import cancelled_white from './cancelled_white.svg';
import default_portal_banner from './default_portal_banner.jpg';
import matterport_svg from './matterport.svg';
import upload_bg from './upload_bg.svg';
import banner_bg from './banner_bg.svg';
import banner_bg_desktop from './banner_bg_1280.svg';
import banner_bg_desktop_lg from './banner_bg_desktop_lg.svg';
import banner_bg_ipad from './banner_bg_ipad.svg';
import banner_bg_ipad_mini from './banner_bg_ipad_mini.svg';
import banner_bg_ipad_pro from './banner_bg_ipad_pro.svg';
import banner_mobile_bg from './banner_mobile.svg';
import banner_mobile_bg_md from './banner_bg_mobile_md.svg';
import banner_mobile_bg_lg from './banner_bg_mobile_lg.svg';
import double_arrow_down from './double_arrow_down.svg';
import shoot_booking from './shoot_booking.png';
import all_services_icon from './all_services_icon.svg';
import step3Image from './step3Image.png';
import centris from './centris.png';
import threeD_picture from './3d_picture.png';
import my_listings from './my_listings.png';
import fb_chat from './desktop_mockup.png';
import matterport_example from './matterport_example.png';
import virtual_tour_icon from './virtual_tour_icon.png';
import services_bg from './services_bg.jpg';
import email_sent from './email_sent.png';
import benefits_of_virtual_tour from './benefits_of_virtual_tour.png';
import previous_arrow from './previous_white.svg';
import next_arrow from './next_white.svg';
import play_icon from './play_icon.svg';
import photo_icon from './photo_icon.svg';
import down_arrow from './down_arrow.svg';
import portal from './portal.png';
import step3 from './step3.png';
import youtube from './youtube.png';
import instagram from './instagram_logo.png';
import twitter from './twitter_logo.png';
import linkedin from './linkedin.png';
import tiktok from './tiktok.png';
import website from './website.svg';
import website_dark_blue from './website_dark_blue.svg';
import tiktok_dark_blue from './tiktok_dark_blue.png';
import simple from './simple.png';
import full_screen from './full_screen.png';
import full_screen_listings from './full_screen_listings.png';
import broker_highlight from './broker_highlight.png';
import broker_highlight_bg from './broker_highlight_bg.svg';
import facebook_icon from './facebook_icon.webp';
import messenger_icon from './messenger_logo.png';
import bathtub_icon from './bathtub_icon.svg';
import bathtub_white from './bathtub_white.svg';
import bathtub_blue from './bathtub_blue.svg';
import bedroom_icon from './bedroom_icon.svg';
import bedroom_white from './bedroom_white.svg';
import bedroom_blue from './bedroom_blue.svg';
import discount from './discount.png';
import extra_photographers_blue from './extra_phographers_blue.png';
import extra_phographers_white from './extra_phographers_white.png';
import chatgpt from './chatgpt.png';
import add_new from './add_new.svg';
import copy from './copy.svg';
import rewrite from './rewrite.svg';
import thumbsup from './thumbsup.png';
import retry from './retry.svg';
import share from './share.png';
import shareBg from './shareBg.svg';
import email from './email.png';
import copy_blue from './copy_blue.svg';
import message from './message.svg';
import moving_waldo_bg from './movingwaldo_bg.png';
import moving_waldo_sm_bg from './movingwaldo_small_cropped_bg.png';
import moving_waldo_md_bg from './movingwaldo_md_bg.png';
import moving_waldo_logo from './MovingWaldo_Logo.svg';
import check_circle from './checkmark.svg';
import add from './add.png';
import subscription from './subscription.svg';
import centris_logo from './centris_logo.png';
import virtual_services_button_en from './virtual_services_button_en.png';
import virtual_services_price_en from './virtual_services_price_en.png';
import virtual_services_photo_en from './virtual_services_photo_en.png';
import virtual_services_code_en from './virtual_services_code_en.png';
import virtual_services_button_fr from './virtual_services_button_fr.png';
import virtual_services_price_fr from './virtual_services_price_fr.png';
import virtual_services_photo_fr from './virtual_services_photo_fr.png';
import virtual_services_code_fr from './virtual_services_code_fr.png';
import furnitures from './furnitures.png';
import chatgpt_example_en from './chatgpt_example_en.png';
import chatgpt_history_en from './chatgpt_history_en.png';
import chatgpt_example_fr from './chatgpt_example_fr.png';
import chatgpt_history_fr from './chatgpt_history_fr.png';
import fast_delivery from './fast_icon.png';
import fast_delivery_blue from './fast_icon_blue.png';
import slideshow from './slideshow.svg';
import slideshow_step1_en from './slideshow_step1_en.png';
import slideshow_step2_en from './slideshow_step2_en.png';
import slideshow_step3_en from './slideshow_step3_en.png';
import slideshow_step4_en from './slideshow_step4_en.png';
import slideshow_step1_fr from './slideshow_step1_fr.png';
import slideshow_step2_fr from './slideshow_step2_fr.png';
import slideshow_step3_fr from './slideshow_step3_fr.png';
import slideshow_step4_fr from './slideshow_step4_fr.png';
import signature_bg from './signature_bg.jpg';
import photo_package_bg from './photo_package_bg.jpg';
import select from './select.svg';
import pickaday from './pickaday.png';
import morning from './sunrise.svg';
import afternoon from './sunset.svg';
import yesterday_white from './yesterday.svg';
import centris_data_transfer_modal_en from './centris_data_transfer_modal_en.png';
import centris_data_transfer_modal_fr from './centris_data_transfer_modal_fr.png';
import centris_data_transfer_not_start_button_en from './centris_data_transfer_not_start_button_en.png';
import centris_data_transfer_not_start_button_fr from './centris_data_transfer_not_start_button_fr.png';
import centris_data_transfer_profile_button_en from './centris_data_transfer_profile_button_en.png';
import centris_data_transfer_profile_button_fr from './centris_data_transfer_profile_button_fr.png';
import commission from './commission.svg';
import files from './files.svg';
import map from './carbon_map.svg';
import work from './work.svg';
import lock from './lock.svg';
import unlock from './unlock.svg';
import direction from './direction.svg';
import destination from './destination.svg';
import aiServices from './aiServices.svg';
import kitchenRoomIcon from './roomIcons/kitchen.svg';
import livingRoomIcon from './roomIcons/livingRoom.svg';
import bedroomIcon from './roomIcons/bedroom.svg';
import bathroomIcon from './roomIcons/bathroom.svg';
import homeOfficeIcon from './roomIcons/office.svg';
import diningRoomIcon from './roomIcons/diningRoom.svg';
import aiStagingExamples from './aiStagingExamples';
import magnifier from './magnifier.svg';
import ai_virtual_services_price_en from './ai_virtual_services_price_en.png';
import ai_virtual_services_photo_en from './ai_virtual_services_photo_en.png';
import ai_virtual_services_select_room_en from './ai_virtual_services_select_room_en.png';
import ai_virtual_services_generate_en from './ai_virtual_services_generate_en.png';
import ai_virtual_services_ai_photos_en from './ai_virtual_services_ai_photos_en.png';
import ai_virtual_services_generated_photos_en from './ai_virtual_services_generated_photos_en.png';
import ai_virtual_services_price_fr from './ai_virtual_services_price_fr.png';
import ai_virtual_services_photo_fr from './ai_virtual_services_photo_fr.png';
import ai_virtual_services_select_room_fr from './ai_virtual_services_select_room_fr.png';
import ai_virtual_services_generate_fr from './ai_virtual_services_generate_fr.png';
import ai_virtual_services_ai_photos_fr from './ai_virtual_services_ai_photos_fr.png';
import ai_virtual_services_generated_photos_fr from './ai_virtual_services_generated_photos_fr.png';
import pallete from './palette.svg';

export const images = {
  loginBg: PORTFOLIO_IMAGES[Math.floor(Math.random() * PORTFOLIO_IMAGES.length)],
  resetPasswordBg: PORTFOLIO_IMAGES[Math.floor(Math.random() * PORTFOLIO_IMAGES.length)],
  logo,
  logo_horizontal,
  logoK,
  logoTitle,
  logoTitleWhite,
  maskGroup,
  maskGroup1,
  dropbox,
  download,
  virtualStaging,
  virtualStagingBg,
  floorplan,
  floorplanMesure,
  deleteIcon,
  download1,
  browser,
  homeIcon,
  starIcon,
  inProgressIcon,
  blueTimeIcon,
  edit,
  edit_shoot,
  avatar,
  comment,
  back,
  prev,
  next,
  floorplanExample,
  listing,
  shooting,
  calendar,
  broker,
  broker_white,
  invoice,
  invoice_blue,
  phone,
  shootingPackage,
  drone,
  photographer,
  editor,
  edit_white,
  status,
  user,
  priority,
  note,
  sd_card,
  add_service_offer,
  link,
  ongoing,
  not_started,
  completed,
  delivered,
  cancelled,
  service_offers,
  upload,
  download_photo,
  virtualTour,
  video360,
  staging,
  floorPlan,
  seasonal,
  measurements,
  blue_hour,
  video,
  upload_picture,
  video_shoot,
  signature,
  matterport,
  info,
  tick,
  standard_3d,
  advanced_3d,
  camera,
  add_shoot,
  address,
  swapArrow,
  happy,
  neutral,
  sad,
  add_listing,
  empty_listing,
  blue_hour_svg,
  video_svg,
  drone_svg,
  no_thumbnail,
  video_white,
  drone_white,
  blue_hour_white,
  floorplan_white,
  virtual_staging_white,
  video360_white,
  reorder,
  upload_white,
  cancelled_white,
  default_portal_banner,
  matterport_svg,
  upload_bg,
  banner_bg,
  banner_bg_desktop,
  banner_bg_desktop_lg,
  banner_mobile_bg,
  banner_mobile_bg_md,
  banner_mobile_bg_lg,
  banner_bg_ipad,
  banner_bg_ipad_mini,
  banner_bg_ipad_pro,
  double_arrow_down,
  shoot_booking,
  all_services_icon,
  step3Image,
  centris,
  threeD_picture,
  my_listings,
  fb_chat,
  matterport_example,
  virtual_tour_icon,
  services_bg,
  email_sent,
  benefits_of_virtual_tour,
  next_arrow,
  previous_arrow,
  play_icon,
  photo_icon,
  down_arrow,
  portal,
  step3,
  youtube,
  instagram,
  twitter,
  linkedin,
  tiktok,
  website,
  website_dark_blue,
  tiktok_dark_blue,
  simple,
  full_screen,
  full_screen_listings,
  broker_highlight,
  broker_highlight_bg,
  facebook_icon,
  messenger_icon,
  bathtub_icon,
  bedroom_icon,
  bathtub_white,
  bathtub_blue,
  bedroom_white,
  bedroom_blue,
  discount,
  extra_photographers_blue,
  extra_phographers_white,
  chatgpt,
  add_new,
  copy,
  rewrite,
  thumbsup,
  retry,
  share,
  shareBg,
  email,
  copy_blue,
  message,
  moving_waldo_bg,
  moving_waldo_sm_bg,
  moving_waldo_md_bg,
  moving_waldo_logo,
  check_circle,
  add,
  subscription,
  centris_logo,
  virtual_services_button_en,
  virtual_services_button_fr,
  virtual_services_price_en,
  virtual_services_price_fr,
  virtual_services_photo_en,
  virtual_services_photo_fr,
  virtual_services_code_en,
  virtual_services_code_fr,
  furnitures,
  chatgpt_example_en,
  chatgpt_history_en,
  chatgpt_example_fr,
  chatgpt_history_fr,
  fast_delivery,
  fast_delivery_blue,
  slideshow,
  slideshow_step1_en,
  slideshow_step2_en,
  slideshow_step3_en,
  slideshow_step4_en,
  slideshow_step1_fr,
  slideshow_step2_fr,
  slideshow_step3_fr,
  slideshow_step4_fr,
  signature_bg,
  photo_package_bg,
  select,
  pickaday,
  afternoon,
  morning,
  yesterday_white,
  centris_data_transfer_modal_en,
  centris_data_transfer_modal_fr,
  centris_data_transfer_not_start_button_en,
  centris_data_transfer_not_start_button_fr,
  centris_data_transfer_profile_button_en,
  centris_data_transfer_profile_button_fr,
  commission,
  files,
  map,
  work,
  lock,
  unlock,
  direction,
  destination,
  aiServices,
  kitchenRoomIcon,
  livingRoomIcon,
  bedroomIcon,
  bathroomIcon,
  homeOfficeIcon,
  diningRoomIcon,
  aiStagingExamples,
  magnifier,
  ai_virtual_services_price_en,
  ai_virtual_services_photo_en,
  ai_virtual_services_select_room_en,
  ai_virtual_services_generate_en,
  ai_virtual_services_ai_photos_en,
  ai_virtual_services_generated_photos_en,
  ai_virtual_services_price_fr,
  ai_virtual_services_photo_fr,
  ai_virtual_services_select_room_fr,
  ai_virtual_services_generate_fr,
  ai_virtual_services_ai_photos_fr,
  ai_virtual_services_generated_photos_fr,
  pallete,
};
