import _ from 'lodash';

import { Room, ShootingParams, ShootingPackage, ServiceOfferDetails } from 'models';
import Listing from './Listing';
import User from './User';
import ServiceOffer from './ServiceOffer';
import Inject from './Inject';

class Shooting {
  id: number;

  additional_km: number;

  auto_send_to_esoft: boolean;

  assigned_part_time: boolean;

  billable: boolean;

  billable_km: number;

  blue_hour: number;

  broker_present: boolean = false;

  cancellation_reason: string;

  client_present: boolean = false;

  completed: boolean;

  current_pp_tech_id: number;

  dispatch_time: string;

  distance: number;

  distance_from_depot: number;

  distance_surcharge: number;

  drone: boolean;

  endtime: string;

  estimated_duration: number;

  force_assigned: boolean;

  hdrs_proc_uploaded: boolean;

  hdrs_uploaded: boolean;

  ignore_scheduler: boolean = false;

  ignore_email: boolean = false;

  direct_drive: boolean = false;

  include_measures: boolean;

  included_km: number;

  is_public_for_broker: boolean;

  key_box: boolean;

  key_box_number: string;

  listing_id: number;

  media_email_sent: boolean;

  media_email_sent_at: string;

  note: string;

  property_highlights: string;

  only_360: boolean;

  ping_sent: boolean;

  ping_time: string;

  plex_unit_count: number;

  pp_tech_id: number;

  previous_shooting_id: string;

  previous_shooting_note: string;

  previous_shooting: Shooting;

  price_per_km: number;

  priority: number;

  production_incomplete: boolean;

  post_production_priority: number;

  quick_reshoot: boolean;

  reshoot_note: string;

  retouch: boolean;

  scheduleddate: string;

  sd_card: string;

  seasonal: boolean;

  service_offers: ServiceOffer[] = [];

  available_service_offers: ServiceOffer[] = [];

  available_shooting_packages: ShootingPackage[] = [];

  recommended_service_offers: ServiceOffer[] = [];

  review_status: number;

  shooting_params: ShootingParams = new ShootingParams();

  square_footage: number = 0;

  starttime: string;

  state: number;

  tech_id: number;

  tech_note: string;

  post_prod_notes: string;

  tech_notification_sent: string;

  transit_time: number;

  uploading: boolean;

  use_backup: boolean;

  zd_ticket_id: string;

  zone_id: string;

  floorplanRooms: Room[] = [];

  tech: User = new User();

  listing: Listing = new Listing();

  all_services_delivered: boolean;

  general_notes: string;

  inject: Inject;

  oldest_service_offer_date: string = '';

  static STATE = [
    'created', // 0
    'captured', // 1
    'validated', // 2
    'retouch', // 3
    'processed', // 4
    'uploaded', // 5
    'confirmed', // 6
    'unconfirmed', // 6
    'inProgress', // 8
    'completed', // 9
    'canceled', // 10
    'confirmationRequired', // 11
    'recommended', // 12
    'abandonned', // 13
    'delivered', // 14
    'draft', // 15
  ];

  static STATE_ENUM = {
    created: 0,
    captured: 1,
    validated: 2,
    retouch: 3,
    processed: 4,
    uploaded: 5,
    confirmed: 6,
    unconfirmed: 7,
    inProgress: 8,
    completed: 9,
    canceled: 10,
    confirmationRequired: 11,
    recommended: 12,
    abandonned: 13,
    delivered: 14,
    draft: 15,
  };

  static DRAFT_STATE = [Shooting.STATE_ENUM.draft];

  static DELIVERED_STATES = [Shooting.STATE_ENUM.uploaded, Shooting.STATE_ENUM.completed];

  static UPCOMING_STATES = [Shooting.STATE_ENUM.confirmed, Shooting.STATE_ENUM.created];

  static COMPLETED_STATES = [Shooting.STATE_ENUM.uploaded];

  static POSTPRODINPROGRESS_STATES = [
    Shooting.STATE_ENUM.processed,
    Shooting.STATE_ENUM.captured,
    Shooting.STATE_ENUM.inProgress,
    Shooting.STATE_ENUM.retouch,
    Shooting.STATE_ENUM.validated,
  ];

  static UNCONFIRMED_STATES = [
    Shooting.STATE_ENUM.created,
    Shooting.STATE_ENUM.unconfirmed,
    Shooting.STATE_ENUM.confirmationRequired,
  ];

  static PRIORITY = {
    LOW: 0,
    MEDIUM: 2,
    HIGH: 3,
    URGENT: 4,
  };

  static HIGH_PRIORITY_BROKERS = [2030, 1065, 1882];

  static REVIEW_STATUS = {
    NOT_READY_FOR_REVIEW: 'notReadyForReview',
    READY_FOR_REVIEW: 'readyForReview',
    REVIEWD_SUCCESSFULLY: 'reviewedSuccessfully',
    REVIEWED_WITH_ISSUES: 'reviewedWithIssues',
  };

  static REVIEW_STATUS_ENUM = {
    NOT_READY_FOR_REVIEW: 0,
    READY_FOR_REVIEW: 1,
    REVIEWD_SUCCESSFULLY: 2,
    REVIEWED_WITH_ISSUES: 3,
  };

  constructor(data: object = {}) {
    Object.assign(this, data);

    if (this.listing) {
      this.listing = new Listing(this.listing);
    }

    if (this.tech) {
      this.tech = new User(this.tech);
    }

    if (this.floorplanRooms) {
      this.floorplanRooms = this.floorplanRooms.map((obj: object) => {
        return new Room(obj);
      });
    }

    if (this.service_offers) {
      this.service_offers = this.service_offers.map((obj: object) => {
        return new ServiceOffer(obj);
      });
    }

    if (this.shooting_params) {
      this.shooting_params = new ShootingParams(this.shooting_params);
    }

    if (this.inject) {
      this.inject = new Inject(this.inject);
    }
  }

  get shootingPackage(): ShootingPackage {
    return this.shooting_params?.shooting_package;
  }

  get freelancerProdCommission(): number {
    return this.shooting_params.shooting_package.freelancer_prod_commission;
  }

  get billableKms(): number {
    return this.additional_km || 0;
  }

  get pricePerBillableKm(): number {
    return this.price_per_km || 0;
  }

  get distanceFee(): number {
    const distanceFee = this.billableKms * this.pricePerBillableKm;
    return _.round(distanceFee, 2);
  }

  get packageCost(): number {
    return Number(this.shootingPackage?.price || 0);
  }

  get serviceOffersCost(): number {
    const servicePrices =
      this.service_offers?.map((m: ServiceOffer) => {
        return m.is_child === true ? 0 : m.serviceOffer.price;
      }) || [];
    const totalServicesPrice = servicePrices.reduce((currentVal, total) => {
      return total + currentVal;
    }, 0);

    return _.round(totalServicesPrice, 2);
  }

  get totalCost(): number {
    return _.round(this.packageCost + this.distanceFee + this.serviceOffersCost, 2);
  }

  get gstCost(): number {
    return _.round(this.totalCost * 0.05, 2);
  }

  get qstCost(): number {
    return _.round(this.totalCost * 0.09975, 2);
  }

  get totalCostWithTax(): number {
    return _.round(this.totalCost * 1.14975, 2);
  }

  get totalShootingPackagePotentialSavings(): number {
    let potentialShootingPackageSaving = 0;
    if (
      !_.isEmpty(this.shootingPackage) &&
      !_.isEmpty(this.shootingPackage.related_shooting_package) &&
      !_.isEmpty(this.shootingPackage.related_shooting_package.shooting_package)
    ) {
      potentialShootingPackageSaving =
        Number(this.shootingPackage.price) -
        this.shootingPackage.related_shooting_package.shooting_package.price;
    }
    return potentialShootingPackageSaving;
  }

  get totalServiceOffersPotentialSavings(): number {
    let potentialServiceOffersSaving = 0;
    this.service_offers.map((so) => {
      if (
        so.serviceOffer &&
        so.serviceOffer.related_service_offer &&
        so.serviceOffer.related_service_offer.service_offer
      ) {
        potentialServiceOffersSaving +=
          so.serviceOffer.price - so.serviceOffer.related_service_offer.service_offer.price;
      }
      return null;
    });
    return potentialServiceOffersSaving;
  }

  get potentialSaving(): number {
    return this.totalShootingPackagePotentialSavings + this.totalServiceOffersPotentialSavings;
  }

  get oldestServiceOfferDate(): string {
    return this.oldest_service_offer_date;
  }

  public set setOldestServiceOfferDate(date: string) {
    this.oldest_service_offer_date = date;
  }

  calculateServiceOffersCost(): number {
    const serviceOffersCommission = this.service_offers.reduce((total, so) => {
      return total + so.serviceOffer.freelancer_prod_commission || 0;
    }, 0);
    return serviceOffersCommission;
  }

  calculateShootingServiceOffersCost(): number {
    const serviceOffersCommission = this.service_offers.reduce((total, so) => {
      return total + so.serviceOffer.freelancer_prod_commission;
    }, 0);
    return serviceOffersCommission + this.shootingPackage.freelancer_prod_commission || 0;
  }
}

export default Shooting;
