import _ from 'lodash';

import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { GOOGLE_MAPS_API_KEY, PLACE_OPTION_TYPES } from 'config/constants';
import { ICanadaPlaceDetails, IOption, IPlaceDetails } from 'types';
import { useCanadaPlaces } from 'hooks';
import { LOADING } from 'state/constants';
import HvAutoComplete from './HvAutoComplete';

interface Props {
  className?: string;
  token: string;
  label: string;
  hideArrow?: boolean;
  inputValue: string;
  onChange: (o: IOption, pd: IPlaceDetails | ICanadaPlaceDetails, type: string) => void;
}

function HvPlacesAutoComplete(props: Props) {
  const intl = useIntl();

  const [place, setPlace] = useState(null);
  const [canadaPlace, setCanadaPlace] = useState(null);
  const [searchText, setSearchText] = useState('');

  const { className, token, label, hideArrow, inputValue, onChange } = props;

  const { placesService, placePredictions, getPlacePredictions } = usePlacesService({
    apiKey: GOOGLE_MAPS_API_KEY,
    options: {
      types: ['address'],
      componentRestrictions: { country: 'CA' },
    },
    language: 'fr',
  });

  const {
    canadaPlacesLoading,
    setCanadaPlacesLoading,
    canadaPlaces,
    getCanadaPlaces,
    getCanadaPlaceDetails,
  } = useCanadaPlaces(token);

  useEffect(() => {
    if (placesService && place) {
      placesService.getDetails({ placeId: place.value }, (placeDetails: IPlaceDetails) => {
        onChange(place, placeDetails, PLACE_OPTION_TYPES.GOOGLE);
      });
    }
  }, [placesService, place]);

  useEffect(() => {
    if (canadaPlace) {
      getCanadaPlaceDetails(canadaPlace.value, (placeDetails: ICanadaPlaceDetails) => {
        onChange(canadaPlace, placeDetails, PLACE_OPTION_TYPES.POST_CANADA);
      });
    }
  }, [canadaPlace]);

  const onInputChange = (search: string = '') => {
    setSearchText(search);
    getPlacePredictions({ input: search });

    setPlace(null);
    setCanadaPlacesLoading(LOADING.NONE);
  };

  const onPlaceSelected = (o: IOption) => {
    if (o.type === PLACE_OPTION_TYPES.GOOGLE) {
      setPlace(o);
    } else if (o.type === PLACE_OPTION_TYPES.POST_CANADA) {
      setCanadaPlace(o);
    } else if (o.type === PLACE_OPTION_TYPES.EXPAND_SEARCH) {
      getCanadaPlaces(searchText);
    }
  };

  const fmtPlaceholder = intl.formatMessage({ id: 'shooting.create.labels.placeholder' });
  const fmtExpandSearch = intl.formatMessage({ id: 'shooting.create.labels.expandSearch' });

  let options = placePredictions.map((m: any) => {
    return { label: m.description, value: m.place_id, type: PLACE_OPTION_TYPES.GOOGLE };
  });

  options = [
    ...options,
    ...canadaPlaces.map((m: any) => {
      return { label: m.description, value: m.id, type: PLACE_OPTION_TYPES.POST_CANADA };
    }),
  ];

  if (!!options.length && canadaPlacesLoading === LOADING.NONE) {
    options = [
      ...options,
      {
        label: fmtExpandSearch,
        value: 'search post canada addresses.',
        type: PLACE_OPTION_TYPES.EXPAND_SEARCH,
      },
    ];
  }

  return (
    <HvAutoComplete
      className={className}
      label={label}
      placeholder={fmtPlaceholder}
      hideArrow={hideArrow}
      inputValue={inputValue}
      specialOptionTypes={[PLACE_OPTION_TYPES.EXPAND_SEARCH]}
      options={options}
      filterOptions={(options: IOption[]) => {
        return options;
      }}
      onChange={onPlaceSelected}
      onInputChange={onInputChange}
    />
  );
}

HvPlacesAutoComplete.defaultProps = {
  className: null,
  token: null,
  label: '',
  hideArrow: false,
  inputValue: '',
  onChange: _.noop,
};

HvPlacesAutoComplete.propTypes = {
  className: PropTypes.string,
  token: PropTypes.string,
  label: PropTypes.string,
  hideArrow: PropTypes.bool,
  inputValue: PropTypes.string,
  onChange: PropTypes.func,
};

export default HvPlacesAutoComplete;
