import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { BrokerFavoriteListing, Broker, Listing } from 'models';
import {
  getPageInfosFromDomain,
  addFavoriteListings,
  fetchBrokerFavoriteListings,
  removeFavoriteListing,
  updateBrokerPublicPageInfos,
  verifyDomain,
  updateBrokerPortalBanner,
  updateBrokerPortalLogo,
  updateBrokerBackgroundImage,
  updateListingOrderIndex,
  fetchDemoListings,
  fetchBrokerPortalListings,
  fetchServiceOfferDemoPictures,
  submitLeadRequest,
} from 'apis';
import { MODAL } from 'config/constants';
import { hideModal } from './app';

const initialState = {
  domain_name: '',
  favorite_listings: [],
  broker: {},
  broker_portal_infos: {},
  addListingsStatus: '',
  isDomainAvailable: true,
  savedInfoStatus: '',
  isDomainTaken: false,
  saveBgImageStatus: '',
  demo_listings: [],
  broker_portal_listings: [],
  saveLogoImageStatus: '',
  saveBannerImageStatus: '',
  no_domain_found: false,
  service_offer_demo_pictures: {},
  centris_listings: [],
  centris_listings_from_db: [],
  all_templates: [],
};

export const personalPortalSlice = createSlice({
  name: 'personalPortal',
  initialState,
  reducers: {
    initialize: (state) => {
      state.domain_name = '';
      state.favorite_listings = [];
      state.broker = {};
      state.broker_portal_infos = {};
      state.addListingsStatus = '';
      state.isDomainAvailable = true;
      state.savedInfoStatus = '';
      state.isDomainTaken = false;
      state.saveBgImageStatus = '';
      state.demo_listings = [];
      state.broker_portal_listings = [];
      state.saveLogoImageStatus = '';
      state.saveBannerImageStatus = '';
      state.no_domain_found = false;
      state.service_offer_demo_pictures = {};
      state.centris_listings = [];
      state.centris_listings_from_db = [];
      state.all_templates = [];
    },
    setFavoriteListings: (state, action) => {
      state.favorite_listings = action.payload;
    },
    setBroker: (state, action) => {
      state.broker = action.payload;
    },
    setBrokerPortalInfos: (state, action) => {
      state.broker_portal_infos = action.payload;
    },
    setAddListingsStatus: (state, action) => {
      state.addListingsStatus = action.payload;
    },
    removeListing: (state, action) => {
      state.favorite_listings = state.favorite_listings.filter((l: any) => {
        return l.listing.id !== action.payload;
      });
    },
    setVerifyDomainResult: (state, action) => {
      state.isDomainAvailable = action.payload;
    },
    setSavedInfoStatus: (state, action) => {
      state.savedInfoStatus = action.payload;
    },
    setReorderedListings: (state, action) => {
      state.favorite_listings = action.payload;
    },
    setDomainTaken: (state, action) => {
      state.isDomainTaken = action.payload;
    },
    setSavedBgImageStatus: (state, action) => {
      state.saveBgImageStatus = action.payload;
    },
    setDemoListings: (state, action) => {
      state.demo_listings = action.payload;
    },
    setDomainName: (state, action) => {
      state.domain_name = action.payload;
    },
    setBrokerPortalListings: (state, action) => {
      state.broker_portal_listings = action.payload;
    },
    setSavedLogoImageStatus: (state, action) => {
      state.saveLogoImageStatus = action.payload;
    },
    setSavedBannerImageStatus: (state, action) => {
      state.saveBannerImageStatus = action.payload;
    },
    setNoDomainFound: (state, action) => {
      state.no_domain_found = action.payload;
    },
    setServiceOfferDemoPictures: (state, action) => {
      state.service_offer_demo_pictures = action.payload;
    },
    setCentrisListings: (state, action) => {
      state.centris_listings = action.payload;
    },
    setCentrisListingsFromDb: (state, action) => {
      state.centris_listings_from_db = action.payload;
    },
    setTemplates: (state, action) => {
      state.all_templates = action.payload;
    },
    addFavoriteListing: (state, action) => {
      state.favorite_listings = state.favorite_listings.concat(action.payload);
    },
  },
});

export const {
  initialize,
  setFavoriteListings,
  setBroker,
  setBrokerPortalInfos,
  setAddListingsStatus,
  removeListing,
  addFavoriteListing,
  setVerifyDomainResult,
  setSavedInfoStatus,
  setReorderedListings,
  setDomainTaken,
  setSavedBgImageStatus,
  setSavedLogoImageStatus,
  setSavedBannerImageStatus,
  setDemoListings,
  setDomainName,
  setBrokerPortalListings,
  setNoDomainFound,
  setServiceOfferDemoPictures,
  setCentrisListings,
  setCentrisListingsFromDb,
  setTemplates,
} = personalPortalSlice.actions;

export const fetchBrokerPublicPortalInfos = (token?: string, domain_name?: string) => {
  return async (dispatch: Dispatch) => {
    const {
      broker,
      broker_favorite_listings,
      broker_portal_infos,
      no_domain_found,
      all_templates,
      centris_listings,
      centris_listings_from_db,
    } = await getPageInfosFromDomain(token, domain_name);
    if (no_domain_found) {
      dispatch(setNoDomainFound(no_domain_found));
    }
    if (broker) {
      dispatch(setBroker(broker));
    }
    if (broker_favorite_listings) {
      dispatch(setFavoriteListings(broker_favorite_listings));
    }
    if (broker_portal_infos) {
      dispatch(setBrokerPortalInfos(broker_portal_infos));
    }
    if (all_templates) {
      dispatch(setTemplates(all_templates));
    }
    if (centris_listings) {
      dispatch(setCentrisListings(centris_listings));
    }
    if (centris_listings_from_db) {
      dispatch(setCentrisListingsFromDb(centris_listings_from_db));
    }
  };
};

export const addFavoriteListingsAsync = (
  token: string,
  listings: Array<Listing>,
  broker_id: string,
) => {
  return async (dispatch: Dispatch) => {
    const res = await addFavoriteListings(token, listings, broker_id);
    if (res && res.status === 200) {
      dispatch(setAddListingsStatus(res.status));
      dispatch(addFavoriteListing(listings));
      dispatch(hideModal(MODAL.ADD_NEW_FAVORITE_LISTING));
    }
  };
};

export const fetchFavoriteListingsAsync = (token: string, broker_id) => {
  return async (dispatch: Dispatch) => {
    const { broker_favorite_listings } = await fetchBrokerFavoriteListings(token, broker_id);
    if (broker_favorite_listings) {
      dispatch(setFavoriteListings(broker_favorite_listings));
    }
  };
};

export const removeFavoriteListingsAsync = (token: string, listing_id: string, broker_id) => {
  return async (dispatch: Dispatch) => {
    const res = await removeFavoriteListing(token, listing_id, broker_id);
    if (res && res.status === 200) {
      dispatch(removeListing(listing_id));
    }
  };
};

export const updateBrokerPublicPageInfosAsync = (
  token: string,
  domain_name: string,
  bio_fr: string,
  bio_en: string,
  display_name: string,
  email: string,
  phone_number: number,
  FBPageID: number,
  pixelID: number,
  instagram: string,
  facebook: string,
  twitter: string,
  linkedin: string,
  youtube: string,
  tiktok: string,
  website: string,
  address: string,
  slogan_fr: string,
  slogan_en: string,
  template_id: number,
) => {
  return async (dispatch: Dispatch) => {
    const res = await updateBrokerPublicPageInfos(
      token,
      domain_name,
      bio_fr,
      bio_en,
      display_name,
      email,
      phone_number,
      FBPageID,
      pixelID,
      instagram,
      facebook,
      twitter,
      linkedin,
      youtube,
      tiktok,
      website,
      address,
      slogan_fr,
      slogan_en,
      template_id,
    );
    if (res && res.status === 200) {
      dispatch(setSavedInfoStatus(200));
      const { broker, broker_portal_infos } = await getPageInfosFromDomain(token, null);
      if (broker && broker_portal_infos) {
        dispatch(setBroker(broker));
        dispatch(setBrokerPortalInfos(broker_portal_infos));
      }
    } else {
      dispatch(setSavedInfoStatus(500));
    }
  };
};

export const verifyDomainNameAsync = (token: string, domain_name: string) => {
  return async (dispatch: Dispatch) => {
    const res = await verifyDomain(token, domain_name);
    if (res && res.status === 200) {
      dispatch(setVerifyDomainResult(true));
    } else {
      dispatch(setVerifyDomainResult(false));
    }
  };
};
export default personalPortalSlice.reducer;

export const updateBrokerPortalBannerAsync = (token: string, formdata: FormData) => {
  return async (dispatch: Dispatch) => {
    const res = await updateBrokerPortalBanner(token, formdata);
    if (res === 200) {
      dispatch(setSavedBannerImageStatus(200));
    } else {
      dispatch(setSavedBannerImageStatus(500));
    }
    dispatch(setSavedBannerImageStatus(''));
  };
};
export const updateBrokerPortalLogoAsync = (token: string, formdata: FormData) => {
  return async (dispatch: Dispatch) => {
    const res = await updateBrokerPortalLogo(token, formdata);
    if (res === 200) {
      dispatch(setSavedLogoImageStatus(200));
    } else {
      dispatch(setSavedLogoImageStatus(500));
    }
    dispatch(setSavedLogoImageStatus(''));
  };
};
export const updateBrokerPortalBackgroundAsync = (token: string, formdata: FormData) => {
  return async (dispatch: Dispatch) => {
    const res = await updateBrokerBackgroundImage(token, formdata);
    if (res === 200) {
      dispatch(setSavedBgImageStatus(200));
      const { broker, broker_favorite_listings, broker_portal_infos } =
        await getPageInfosFromDomain(token, null);
      if (broker && broker_favorite_listings && broker_portal_infos) {
        dispatch(setBroker(broker));
        dispatch(setFavoriteListings(broker_favorite_listings));
        dispatch(setBrokerPortalInfos(broker_portal_infos));
      }
    } else {
      dispatch(setSavedBgImageStatus(500));
    }
    dispatch(setSavedBgImageStatus(''));
  };
};

export const updateFavoriteListingsOrderAsync = (token: string, reordered_listings) => {
  return async (dispatch: Dispatch) => {
    reordered_listings.map((l, index) => {
      l.order_index = index;
      return null;
    });
    const res = await updateListingOrderIndex(token, reordered_listings);
    if (res && res.status === 200) {
      dispatch(setReorderedListings(reordered_listings));
    }
  };
};

export const fetchDemoListingsAsync = (token: string) => {
  return async (dispatch: Dispatch) => {
    const { demo_listings } = await fetchDemoListings(token);
    if (demo_listings) {
      dispatch(setDemoListings(demo_listings));
    }
  };
};

export const fetchBrokerPortalListingsAsync = (token: string) => {
  return async (dispatch: Dispatch) => {
    const { listings } = await fetchBrokerPortalListings(token);
    if (listings) {
      dispatch(setBrokerPortalListings(listings));
    }
  };
};

export const fetchServiceOfferDemoPicturesAsync = () => {
  return async (dispatch: Dispatch) => {
    const { demo_pictures } = await fetchServiceOfferDemoPictures();
    if (demo_pictures) {
      dispatch(setServiceOfferDemoPictures(demo_pictures));
    }
  };
};
export const submitLeadRequestAsync = (formData: any) => {
  return async (dispatch: Dispatch) => {
    const res = await submitLeadRequest(formData);
    if (res && res.status === 200) {
      return true;
    }
    return false;
  };
};
