import _ from 'lodash';
import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';

interface BeforeAfterSliderProps {
  beforeImage: string;
  afterImage: string;
  checkAndSetSize: (beforePicture: any, afterPicture: any) => { height: number; width: number };
}

const useStyles = makeStyles({
  sliderContainer: {
    position: 'relative',
    width: '100%',
    userSelect: 'none',
    '-webkit-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    marginBottom: 10,
  },
  beforeImage: {
    position: 'absolute',
    width: '100%',
    top: 0,
    left: 0,
    '& img': {
      display: 'block',
      width: '100%',
    },
  },
  afterImage: {
    position: 'absolute',
    width: '100%',
    top: 0,
    left: 0,
    '& img': {
      display: 'block',
      width: '100%',
    },
  },
  sliderHandle: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: 3,
    backgroundColor: 'white',
    cursor: 'ew-resize',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconStyle: {
    color: '#FFF',
  },
  iconBox: {
    height: 40,
    width: 40,
    borderRadius: '50%',
    border: 'solid white 2px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

function BeforeAfterSlider({ beforeImage, afterImage, checkAndSetSize }: BeforeAfterSliderProps) {
  const classes = useStyles();
  const [sliderPosition, setSliderPosition] = useState(70);
  const [isDragging, setIsDragging] = useState(false);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const sliderRef = useRef<HTMLDivElement>(null);
  const beforeImageRef = useRef(null);
  const afterImageRef = useRef(null);

  const handleMouseMove = (event: React.MouseEvent) => {
    if (isDragging && sliderRef.current) {
      const rect = sliderRef.current.getBoundingClientRect();
      const newPosition = ((event.clientX - rect.left) / rect.width) * 100;
      setSliderPosition(Math.min(Math.max(newPosition, 1), 99));
    }
  };

  const handleTouchStart = (event: React.TouchEvent) => {
    setIsDragging(true);
  };

  const handleTouchMove = (event: React.TouchEvent) => {
    if (isDragging && sliderRef.current) {
      const rect = sliderRef.current.getBoundingClientRect();
      const newPosition = ((event.touches[0].clientX - rect.left) / rect.width) * 100;
      setSliderPosition(Math.min(Math.max(newPosition, 1), 99));
    }
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  function loadImagesSize() {
    const beforePicture = new Image();
    const afterPicture = new Image();

    let beforePictureLoaded = false;
    let afterPictureLoaded = false;

    const onImageLoad = () => {
      if (beforePictureLoaded && afterPictureLoaded) {
        const { width, height } = checkAndSetSize(beforePicture, afterPicture);
        beforePicture.width = width;
        beforePicture.height = height;
        afterPicture.width = width;
        afterPicture.height = height;
        setDimensions({ width, height });
        if (beforeImageRef.current.firstChild && afterImageRef.current.firstChild) {
          beforeImageRef.current.firstChild.style.width = `${width}px`;
          afterImageRef.current.firstChild.style.width = `${width}px`;
          beforeImageRef.current.firstChild.style.height = `${height}px`;
          afterImageRef.current.firstChild.style.height = `${height}px`;
        } else {
          beforeImageRef.current.appendChild(beforePicture);
          afterImageRef.current.appendChild(afterPicture);
        }
      }
    };

    beforePicture.onload = () => {
      beforePictureLoaded = true;
      onImageLoad();
    };
    afterPicture.onload = () => {
      afterPictureLoaded = true;
      onImageLoad();
    };

    beforePicture.src = beforeImage;
    afterPicture.src = afterImage;
  }

  const handleResize = _.debounce(() => {
    loadImagesSize();
  }, 250);

  useEffect(() => {
    loadImagesSize();
    window.addEventListener('resize', handleResize);
    return () => {
      return window.removeEventListener('resize', handleResize);
    };
  }, [beforeImage, afterImage]);

  return (
    <div
      className={classes.sliderContainer}
      style={{ width: dimensions.width, height: dimensions.height }}
      ref={sliderRef}
      key={afterImage}
      onMouseMove={handleMouseMove}
      onMouseLeave={() => {
        return setIsDragging(false);
      }}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div className={classes.beforeImage}>
        <div ref={beforeImageRef} />
      </div>
      <div
        className={classes.afterImage}
        style={{
          clipPath: `polygon(0 0, ${sliderPosition}% 0, ${sliderPosition}% 100%, 0 100%)`,
        }}
      >
        <div ref={afterImageRef} />
      </div>
      <div
        className={classes.sliderHandle}
        style={{ left: `${sliderPosition}%` }}
        onMouseDown={(event) => {
          event.preventDefault();
          return setIsDragging(true);
        }}
        onMouseUp={() => {
          return setIsDragging(false);
        }}
      >
        <div className={classes.iconBox}>
          <ChevronLeftRoundedIcon className={classes.iconStyle} />
          <ChevronRightRoundedIcon className={classes.iconStyle} />
        </div>
      </div>
    </div>
  );
}

export default BeforeAfterSlider;
