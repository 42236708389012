import _ from 'lodash';
import axios from 'axios';

import { Listing, ServiceOffer, ServiceOfferDetails, Shooting, Picture } from 'models';
import { IAmenity, IDistanceFeeDetail, ITimeSlot } from 'types';
import { getAPIURL, CURRENT_APP_VERSION, STATE } from 'config/constants';
import moment from 'moment';

export const fetchShootings = async (token: string): Promise<Shooting[]> => {
  try {
    const config = { headers: { token } };
    const res = await axios.get(`${getAPIURL()}/getAllShootings`, config);
    const shootings = res.data.data.map((obj: object) => {
      return new Shooting(obj);
    });
    return shootings;
  } catch (err) {
    console.log('[fetchShootings] error: ', err);
  }
  return [];
};

export const fetchAssignedShootings = async (token: string): Promise<Shooting[]> => {
  try {
    const config = { headers: { token } };
    const res = await axios.get(`${getAPIURL()}/getAssignedShootings`, config);
    const shootings = res.data.shootings.map((obj: object) => {
      return new Shooting(obj);
    });
    return shootings;
  } catch (err) {
    console.log('[fetchAssignedShootings] error: ', err);
  }
  return [];
};

export const fetchShootingQueue = async (token: string): Promise<Shooting[]> => {
  try {
    const config = { headers: { token } };
    const res = await axios.get(`${getAPIURL()}/getShootingQueue`, config);
    const shootings = res.data.shootings.map((obj: object) => {
      return new Shooting(obj);
    });
    return shootings;
  } catch (err) {
    console.log('[fetchShootingQueue] error: ', err);
  }
  return [];
};

export const assignShooting = async (token: string, sId: number, tId: number) => {
  try {
    const config = { headers: { token } };
    const params = { shooting_id: sId, pp_tech_id: tId };
    const res = await axios.post(`${getAPIURL()}/assignShooting`, params, config);
    return res;
  } catch (err) {
    console.log('[assignShooting] error: ', err);
  }
  return null;
};

export const unassignShooting = async (token: string, sId: number) => {
  try {
    const config = { headers: { token } };
    const params = { shooting_id: sId };
    const res = await axios.post(`${getAPIURL()}/unassignShooting`, params, config);
    return res;
  } catch (err) {
    console.log('[unassignShooting] error: ', err);
  }
  return null;
};

export const cancelShooting = async (token: string, id: number, reason: string) => {
  try {
    const config = { headers: { token } };
    const params = { shooting_id: id, cancellation_reason: reason };
    const res = await axios.put(`${getAPIURL()}/cancelShooting`, params, config);
    return res?.data?.status === 200;
  } catch (err) {
    return false;
  }
};

export const deleteShootingDraftApi = async (token: string, id: number) => {
  try {
    const config = { headers: { token } };
    const params = { shooting_id: id };
    const res = await axios.put(`${getAPIURL()}/deleteShootingDraft`, params, config);
    return res?.data?.status === 200;
  } catch (err) {
    return false;
  }
};

export const fetchShootingData = async (token: string, sId: number) => {
  try {
    const config = { headers: { token } };
    const res = await axios.get(`${getAPIURL()}/getShootingData?shooting_id=${sId}`, config);
    return new Shooting(res.data.data);
  } catch (err) {
    console.log('[fetchShootingData] error: ', err);
  }

  return null;
};

export const setShootingPriority = async (token: string, sId: number, priority: number) => {
  try {
    const config = { headers: { token } };
    const params = { shooting_id: sId, priority };
    await axios.post(`${getAPIURL()}/setShootingPostProductionPriority`, params, config);
    return true;
  } catch (err) {
    console.log('[setShootingPriority] error: ', err);
    return false;
  }
};

export const setShootingReviewStatus = async (
  token: string,
  user: string,
  shooting_id: number,
  review_status: number,
) => {
  try {
    const config = { headers: { token } };
    const params = { shooting_id, user, review_status };
    await axios.post(`${getAPIURL()}/setShootingReviewStatus`, params, config);
    return true;
  } catch (err) {
    console.log('[setShootingReviewStatus] error: ', err);
    return false;
  }
};

export const assignSDCard = async (token: string, shooting_id: number, sd_card: string) => {
  try {
    const config = { headers: { token } };
    const params = { shooting_id, sd_card };
    const res = await axios.post(`${getAPIURL()}/assignSDCard`, params, config);
    return res.data;
  } catch (err) {
    console.log('[assignSDCard] error: ', err);
  }
  return null;
};

export const savePostProdNote = async (
  token: string,
  shooting_id: number,
  post_prod_notes: string,
) => {
  try {
    const config = { headers: { token } };
    const params = { shooting: { id: shooting_id, post_prod_notes } };
    const res = await axios.post(`${getAPIURL()}/setShootingNotes`, params, config);
    return res.data;
  } catch (err) {
    console.log('[setShootingNotes] error: ', err);
  }
  return null;
};
export const saveProdNote = async (token: string, shooting_id: number, tech_note: string) => {
  try {
    const config = { headers: { token } };
    const params = { shooting: { id: shooting_id, tech_note } };
    const res = await axios.post(`${getAPIURL()}/setShootingNotes`, params, config);
    return res.data;
  } catch (err) {
    console.log('[setShootingNotes] error: ', err);
  }
  return null;
};
export const addNewServiceOffer = async (
  token: string,
  shooting_id: number,
  service_id: number,
  actor: string,
  action: string,
) => {
  try {
    const config = { headers: { token } };
    const params = { shooting_id, service_id, actor, action };
    const res = await axios.post(`${getAPIURL()}/addServiceToShooting`, params, config);
    return res.data;
  } catch (err) {
    console.log('[addServiceToShooting] error: ', err);
  }
  return null;
};
export const updateShootingService = async (
  token: string,
  id: number,
  url: number,
  state: number,
  note: string,
  pp_tech_id: string,
  actor: string,
  action: string,
  assignee_id: string,
  assignee_name: string,
) => {
  try {
    const config = { headers: { token } };
    const params = { id, url, state, note, pp_tech_id, actor, action, assignee_id, assignee_name };
    const res = await axios.post(`${getAPIURL()}/updateServiceOffer`, params, config);
    return res.data;
  } catch (error) {
    console.log('[updateServiceOffer] error: ', error);
  }
  return null;
};
export const updateTicket = async (token: string, id: number, state: number) => {
  try {
    const config = { headers: { token } };
    const params = { id, state };
    const res = await axios.put(`${getAPIURL()}/ticket`, params, config);
    return res.data;
  } catch (error) {
    console.log('[updateTicket] error: ', error);
  }
  return null;
};
export const fetchDistanceFee = async (
  token: string,
  brokerId: number,
  lat: string,
  lng: string,
): Promise<IDistanceFeeDetail> => {
  try {
    const config = { headers: { token } };
    const params = `broker_id=${brokerId}&lat=${lat}&lng=${lng}`;
    const res = await axios.get(`${getAPIURL()}/getDistanceFees?${params}`, config);
    const { status, ...shootingData } = res.data;
    return shootingData as IDistanceFeeDetail;
  } catch (err) {
    return {} as IDistanceFeeDetail;
  }
};

export const fetchServiceOffers = async (
  token: string,
  brokerId: number,
  lat: string,
  lng: string,
): Promise<any> => {
  try {
    const config = { headers: { token } };
    const params = `broker_id=${brokerId}&lat=${lat}&lng=${lng}&min_app_version=${CURRENT_APP_VERSION}`;
    const res = await axios.get(`${getAPIURL()}/getServiceOffersForShooting?${params}`, config);
    return res.data.data
      .map((service: any) => {
        const { type, value, ...serviceOffer } = service;
        return {
          type_value_map: { type, value: value === true },
          serviceOffer,
        };
      })
      .sort((a: ServiceOffer, b: ServiceOffer) => {
        return a.serviceOffer.order_index - b.serviceOffer.order_index;
      });
  } catch (err) {
    return false;
  }
};

export const fetchShootingAmenities = async (token: string): Promise<any> => {
  try {
    const config = { headers: { token } };
    const res = await axios.get(`${getAPIURL()}/getShootingAmenitiesForCondo`, config);
    return res.data.data;
  } catch (err) {
    return false;
  }
};

export const fetchShootingPackages = async (token: string, brokerId: number): Promise<any> => {
  try {
    const config = { headers: { token } };
    const res = await axios.get(
      `${getAPIURL()}/getShootingPackagesForBroker?broker_id=${brokerId}`,
      config,
    );
    let packages = res.data.data.filter((m: any) => {
      return !!m.id;
    });
    packages = packages.sort((a: any, b: any) => {
      return a.order_index - b.order_index;
    });
    return packages;
  } catch (err) {
    return false;
  }
};

export const checkServiceability = async (
  token: string,
  lat: string,
  lng: string,
): Promise<any> => {
  try {
    const config = { headers: { token } };
    const params = `lat=${lat}&lng=${lng}`;
    const res = await axios.get(`${getAPIURL()}/checkShootingServiceability?${params}`, config);
    return res.data;
  } catch (err) {
    return null;
  }
};

export const fetchLongDistanceServiceOffer = async (
  token: string,
  brokerId: number,
): Promise<any> => {
  try {
    const config = { headers: { token } };
    const res = await axios.get(
      `${getAPIURL()}/getLongDistanceServiceOffer?broker_id=${brokerId}`,
      config,
    );
    return res.data.data;
  } catch (err) {
    return false;
  }
};

export const fetchShootingParams = async (
  token: string,
  brokerId: number,
  lat: string,
  lng: string,
): Promise<any> => {
  return Promise.all([
    fetchDistanceFee(token, brokerId, lat, lng),
    fetchShootingAmenities(token),
    fetchShootingPackages(token, brokerId),
    fetchServiceOffers(token, brokerId, lat, lng),
    fetchLongDistanceServiceOffer(token, brokerId),
  ])
    .then((results) => {
      if (results[1] === false || results[2] === false || results[3] === false) {
        return false;
      }
      return {
        distanceFee: results[0],
        amenities: results[1],
        packages: results[2],
        serviceOffers: results[3],
        longDistanceServiceOffer: results[4],
      };
    })
    .catch(() => {
      return false;
    });
};

export const fetchRecommendedPackage = async (
  token: string,
  brokerId: number,
  propertyType: number,
  roomCount: number,
  amenities: string[],
): Promise<number> => {
  try {
    const config = { headers: { token } };
    const params = `broker_id=${brokerId}&property_type=${propertyType}&room_count=${roomCount}&amenities=${amenities}`;
    const res = await axios.get(
      `${getAPIURL()}/getRecommendedPackageForShooting?${params}`,
      config,
    );
    return res.data.data;
  } catch (err) {
    return -1;
  }
};

export const scheduleShooting = async (
  token: string,
  timeSlot: ITimeSlot,
  shooting: Shooting,
  listing: Listing,
  isFake: boolean,
): Promise<Shooting> => {
  if (isFake) return null;

  if (
    !listing?.address ||
    !listing?.city ||
    !listing?.postalcode ||
    !shooting?.shooting_params?.room_count
  ) {
    return null;
  }

  let _serviceOffers = shooting.service_offers.map((item: ServiceOffer) => {
    return {
      id: item.serviceOffer.id,
      name: item.serviceOffer.key_name,
      price: item.serviceOffer.price,
      type_value_map: item.type_value_map,
      is_child: item.is_child === true,
    };
  });

  _serviceOffers = _serviceOffers.filter((so: any) => {
    return so.is_child === false;
  });

  // const amenities: IAmenity[] = shooting.shooting_params.getAmenities();
  // const shootingPackages = shooting.shooting_params.shooting_package;
  // shootingPackages.included_service_offers = shootingPackages.included_service_offers ?? [];
  // const shootingParams = { ...shooting.shooting_params, amenities, shootingPackages };
  const amenities = shooting.shooting_params.getStringAmenities();
  const shootingParams = { ...shooting.shooting_params, amenities };

  if (shootingParams.shooting_package) {
    shootingParams.shooting_package_id = shootingParams.shooting_package.id;
  }
  const _shootingParams = JSON.stringify(
    _.omit(shootingParams, [
      'shooting_package',
      'shooting_package_name',
      'shooting_package_raw',
      'shooting_package_included_service_offers',
      'shooting_package',
      'package_id',
    ]),
  );

  const params = {
    shooting_id: shooting.id,
    listing_id: listing.id,
    address: listing.address,
    civic_number: listing.civic_number,
    street: listing.street,
    city: listing.city,
    postalcode: listing.postalcode,
    only_360: shooting.only_360,
    property_type: listing.property_type,
    room_count: shooting.shooting_params.room_count,
    square_footage: shooting.square_footage,
    lat: listing.lat,
    lng: listing.lng,
    broker_present: shooting.broker_present,
    client_present: shooting.client_present,
    key_box: shooting.key_box,
    scheduleddate: timeSlot.date,
    use_backup: timeSlot.use_backup,
    client_email: shooting.shooting_params.client_email,
    ignore_scheduler: shooting.ignore_scheduler,
    ignore_email: shooting.ignore_email,
    direct_drive: timeSlot.direct_drive,
    service_offers: _serviceOffers,
    shooting_params: _shootingParams,
    key_box_number: shooting.key_box_number,
    billable_km: shooting.billable_km,
    mls: listing.mls,
    app: listing.app,
    note: shooting.note,
    property_highlights: shooting.property_highlights,
    broker_id: listing.broker_id,
    plex_unit_count: shooting.plex_unit_count,
  };
  try {
    const config = { headers: { token } };
    const res = await axios.post(`${getAPIURL()}/scheduleShooting`, params, config);
    return new Shooting(res.data.shooting);
  } catch (err) {
    console.log('[scheduleShooting] error: ', err);
    return null;
  }
};

export const saveShootingDraft = async (
  token: string,
  shooting: Shooting,
  listing: Listing,
): Promise<Shooting> => {
  if (
    !listing?.address ||
    !listing?.city ||
    !listing?.postalcode ||
    !shooting?.shooting_params?.room_count
  ) {
    return null;
  }

  let _serviceOffers = shooting.service_offers.map((item: ServiceOffer) => {
    return {
      id: item.serviceOffer.id,
      name: item.serviceOffer.key_name,
      price: item.serviceOffer.price,
      type_value_map: item.type_value_map,
      is_child: item.is_child === true,
    };
  });

  _serviceOffers = _serviceOffers.filter((so: any) => {
    return so.is_child === false;
  });

  const amenities = shooting.shooting_params.getStringAmenities();
  const shootingParams = { ...shooting.shooting_params, amenities };

  if (shootingParams.shooting_package) {
    shootingParams.shooting_package_id = shootingParams.shooting_package.id;
  }
  const _shootingParams = JSON.stringify(
    _.omit(shootingParams, [
      'shooting_package',
      'shooting_package_name',
      'shooting_package_raw',
      'shooting_package_included_service_offers',
      'shooting_package',
      'package_id',
    ]),
  );

  const params = {
    shooting_id: shooting.id,
    listing_id: listing.id,
    address: listing.address,
    civic_number: listing.civic_number,
    street: listing.street,
    city: listing.city,
    postalcode: listing.postalcode,
    only_360: shooting.only_360,
    property_type: listing.property_type,
    room_count: shooting.shooting_params.room_count,
    square_footage: shooting.square_footage,
    lat: listing.lat,
    lng: listing.lng,
    broker_present: shooting.broker_present,
    client_present: shooting.client_present,
    key_box: shooting.key_box,
    scheduleddate: null,
    use_backup: false,
    client_email: shooting.shooting_params.client_email,
    ignore_scheduler: shooting.ignore_scheduler,
    ignore_email: shooting.ignore_email,
    direct_drive: false,
    service_offers: _serviceOffers,
    shooting_params: _shootingParams,
    key_box_number: shooting.key_box_number,
    billable_km: shooting.billable_km,
    mls: listing.mls,
    app: listing.app,
    note: shooting.note,
    property_highlights: shooting.property_highlights,
    broker_id: listing.broker_id,
    plex_unit_count: shooting.plex_unit_count,
  };
  try {
    const config = { headers: { token } };
    const res = await axios.post(`${getAPIURL()}/saveShootingDraft`, params, config);
    const { shooting } = res.data;
    return new Shooting(shooting);
  } catch (err) {
    console.log('[saveShootingDraft] error: ', err);
    return null;
  }
};
export const updateShooting = async (token: string, shooting: Shooting, listing: Listing) => {
  const { shooting_params } = shooting;

  try {
    const config = { headers: { token } };
    const params = {
      listing_id: listing.id,
      shooting_id: shooting.id,
      note: shooting.note,
      property_highlights: shooting.property_highlights,
      broker_present: shooting.broker_present,
      client_present: shooting.client_present,
      key_box_number: shooting.key_box_number,
      app: listing.app,
      plex_unit_count: shooting.plex_unit_count,
      client_name: shooting_params.client_name,
      client_email: shooting_params.client_email,
      client_phone: shooting_params.client_phone,
      client_preferred_language: shooting_params.client_preferred_language,
      mls: listing.mls,
      selectedBroker: listing.broker_id,
    };

    const res = await axios.put(`${getAPIURL()}/updateShooting`, params, config);
    const { data } = res.data;

    return {
      id: data.id,
      note: data.note,
      property_highlights: data.property_highlights,
      broker_present: data.broker_present,
      client_present: data.client_present,
      key_box_number: data.key_box_number,
      plex_unit_count: data.plex_unit_count,
      shooting_params: {
        client_name: data.shooting_params.client_name,
        client_email: data.shooting_params.client_email,
        client_phone: data.shooting_params.client_phone,
        client_preferred_language: data.shooting_params.client_preferred_language,
      },
      listing: {
        app: data.listing.app,
        mls: data.listing.mls,
      },
    };
  } catch (error) {
    return null;
  }
};

export const fetchShootingsFromTech = async (
  user_id: number,
  startDate: string,
  endDate: string,
  assigned: any,
  token: string,
): Promise<Shooting[]> => {
  try {
    const res = await axios.post(
      `${getAPIURL()}/shootingFromTech`,
      { user_id, startDate, endDate, assigned },
      { headers: { token } },
    );
    const shootings = res.data.shootings.map((obj: object) => {
      return new Shooting(obj);
    });
    return shootings;
  } catch (err) {
    console.log('[fetchShootingsFromTech] error: ', err);
  }
  return [];
};

export const fetchShootingsFromPostTech = async (
  tech_id: number,
  start_date: string,
  end_date: string,
  token: string,
): Promise<Shooting[]> => {
  try {
    const res = await axios.post(
      `${getAPIURL()}/getPostTechShootings`,
      { tech_id, start_date, end_date },
      { headers: { token } },
    );
    const shootings = res.data.shootings.map((obj: object) => {
      return new Shooting(obj);
    });
    return shootings;
  } catch (err) {
    console.log('[fetchShootingsFromTech] error: ', err);
  }
  return [];
};

export const fetchUndeliveredShootings = async (
  token: string,
  searchInput: string = '',
  page?: number,
  loadAll: boolean = false,
) => {
  try {
    const config = { headers: { token } };
    let url = `getUndeliveredShootings?page=${page - 1}`;
    if (loadAll) {
      url = `getUndeliveredShootings?loadAll=${loadAll}`;
    }
    if (searchInput) {
      url += `&searchInput=${searchInput}`;
    }
    const res = await axios.get(`${getAPIURL()}/${url}`, config);
    const { shootings } = res.data;
    const undeliveredShootings = shootings.map((shooting: object) => {
      return new Shooting(shooting);
    });
    return { undeliveredShootings, pageTotalCount: res.data.totalPages };
  } catch (error) {
    console.log('[fetchUndeliveredShootings] error: ', error);
  }
  return [];
};
export const generateInvoice = async (token: string, shooting_id: number) => {
  try {
    const config = { headers: { token } };
    const params = { shooting_id };
    const res = await axios.post(`${getAPIURL()}/generateShootingInvoice`, params, config);

    return res.data;
  } catch (error) {
    console.log('[generateInvoice] error: ', error);
  }
  return [];
};
export const completedAndSentShooting = async (token: string, shooting_id: number) => {
  try {
    const config = { headers: { token } };
    const params = { shooting_id };
    const res = await axios.post(`${getAPIURL()}/completedAndSentShooting`, params, config);
    console.log('completedAndSentShooting', res.data);
    return res.data;
  } catch (error) {
    console.log('[completedAndSentShooting] error: ', error);
  }
  return [];
};
export const fetchAllServiceOffers = async (token: string) => {
  try {
    const config = { headers: { token } };
    const res = await axios.get(`${getAPIURL()}/getAllManageableServiceOffers`, config);
    return res.data;
  } catch (error) {
    console.log('[fetchAllServiceOffers] error: ', error);
  }
  return [];
};
export const fetchAllAvailabiliesServiceOffers = async (token: string) => {
  try {
    const config = { headers: { token } };
    const res = await axios.get(`${getAPIURL()}/getAllAvailabilitiesServiceOffers`, config);
    return res.data.data;
  } catch (error) {
    console.log('[getAllAvailabilitiesServiceOffers] error: ', error);
  }
  return [];
};
export const updateServiceOfferRecommendation = async (token: string, service_offer) => {
  try {
    const config = { headers: { token } };
    const params = { service_offer };
    const res = await axios.put(`${getAPIURL()}/serviceOffer`, params, config);
    return res.data;
  } catch (error) {
    console.log('[updateServiceOfferRecommendation] error: ', error);
  }
  return null;
};
export async function setHdrsProcUploaded(shooting_id: number, token: string) {
  try {
    const config = { headers: { token } };
    const params = { shooting_id };
    const res = await axios.post(`${getAPIURL()}/setHDRSProcUploaded`, params, config);
    return res.data;
  } catch (error) {
    console.log('[sendShootingToReview] error: ', error);
  }
  return null;
}
export async function getUserShootings(
  user_id: number,
  date_range: any,
  is_admin = false,
  token: string,
) {
  try {
    const config = { headers: { token } };
    const params = { user_id, date_range, is_admin, token };
    const res = await axios.post(`${getAPIURL()}/getUserShootings`, params, config);
    const { shootings } = res.data;
    return shootings;
  } catch (error) {
    console.log('[getUserShootings] error: ', error);
  }
  return null;
}

export async function fetchAllShootingPackages(token: string) {
  try {
    const config = { headers: { token } };
    const res = await axios.get(`${getAPIURL()}/shootingPackage`, config);
    let packages = res.data.data.filter((m: any) => {
      return !!m.id;
    });
    packages = packages.sort((a: any, b: any) => {
      return a.order_index - b.order_index;
    });
    return packages;
  } catch (err) {
    return false;
  }
}

export async function saveCustomServiceOffer(
  token: string,
  id: number,
  selectedSubscription: number,
  selectedServiceOfferHDRItem: number,
  selectedServiceOffers: any,
  price: number,
  freelancer_prod_commission: number,
  processing_time_in_minutes: number,
  zoho_item_id: string,
  display_name: string,
  display_name_fr: string,
  description: string,
  description_fr: string,
  brokers: number[],
  photographers: number[],
  available: boolean,
  gf_clause: boolean,
  is_global: boolean,
  recommendation_max: number,
) {
  try {
    const config = { headers: { token } };
    const params = {
      service_offer_id: id,
      subscription_id: selectedSubscription,
      service_offer_hdr_item: selectedServiceOfferHDRItem,
      service_offer_ids: selectedServiceOffers,
      price,
      freelancer_prod_commission,
      processing_time_in_minutes,
      zoho_item_id,
      display_name,
      display_name_fr,
      description,
      description_fr,
      users: brokers,
      photographers,
      available,
      gf_clause,
      is_global,
      recommendation_max,
    };
    const res = await axios.post(`${getAPIURL()}/customServiceOffer`, params, config);
    return res.data.status;
  } catch (err) {
    return false;
  }
}

export const fetchAllCustomServiceOffers = async (token: string) => {
  try {
    const config = { headers: { token } };
    const res = await axios.get(`${getAPIURL()}/getAllCustomServiceOffers`, config);
    return res.data.data;
  } catch (error) {
    console.log('[fetchAllCustomServiceOffers] error: ', error);
  }
  return [];
};

export const deleteServiceOffer = async (token: string, serviceOfferId: number) => {
  try {
    const config = { headers: { token } };
    const res = await axios.delete(`${getAPIURL()}/customServiceOffer/${serviceOfferId}`, config);
    return res.data.status;
  } catch (error) {
    return null;
  }
};

export const fetchAllServiceOfferItems = async (token: string) => {
  try {
    const config = { headers: { token } };
    const res = await axios.get(`${getAPIURL()}/serviceOfferItems`, config);
    return res.data.data;
  } catch (error) {
    console.log('[fetchAllServiceOfferItems] error: ', error);
  }
  return [];
};

export const toggleAutoSendToEsoft = async (
  token: string,
  shooting_id: number,
  auto_send_to_esoft: boolean,
) => {
  const config = { headers: { token } };
  const params = { shooting_id, auto_send_to_esoft };
  const response = await axios.post(`${getAPIURL()}/toggleAutoSendToEsoft`, params, config);
  return response;
};

export async function setInjectPictureCount(
  shooting_id: number,
  listing_id: number,
  token: string,
) {
  try {
    const config = { headers: { token } };
    const params = { shooting_id, listing_id };
    const response = await axios.post(`${getAPIURL()}/setInjectFileCount`, params, config);
    return response;
  } catch (error) {
    console.log('[fetchAllServiceOfferItems] error: ', error);
  }
}

export const fetchDailyShootingActivity = async (token: string, timestamp: string) => {
  try {
    const config = { headers: { token } };
    const res = await axios.get(
      `${getAPIURL()}/dailyShootingActivity?timestamp=${timestamp}`,
      config,
    );
    return res.data.data;
  } catch (error) {
    console.log('[fetchDailyShootingActivity] error: ', error);
  }
  return [];
};

export const fetchAllTechLocksForTheDay = async (token: string, date: string) => {
  try {
    const config = { headers: { token } };
    const res = await axios.get(`${getAPIURL()}/tech_locks?date=${date}`, config);
    return res.data.locks;
  } catch (error) {
    console.log('[fetchTechLocks] error: ', error);
  }
  return [];
};

export const updateTechStatus = async (
  token: string,
  tech_id: number,
  locked: boolean,
  date: string,
) => {
  try {
    const config = { headers: { token } };
    const params = { tech_id, locked, date };
    const res = await axios.post(`${getAPIURL()}/updateLockStatus`, params, config);
    return res;
  } catch (error) {
    console.log('[updateLockStatus] error: ', error);
  }
};

export const updateShootingParams = async (
  token: string,
  shooting_id: number,
  scheduleddate: string,
  room_count: number,
  package_id?: number,
) => {
  try {
    const config = { headers: { token } };
    const params = { shooting_id, scheduleddate, room_count, package_id };
    const res = await axios.post(`${getAPIURL()}/updateShootingParams`, params, config);
    return res;
  } catch (error) {
    console.log('[updateShootingParams] error: ', error);
  }
};

export const recalculateDistances = async (token: string, date: string) => {
  try {
    const config = { headers: { token } };
    const params = { date };
    const res = await axios.post(`${getAPIURL()}/recalculateShootingDistances`, params, config);
    return res;
  } catch (error) {
    console.log('[recalculateDistances] error: ', error);
  }
};

export const assignProdShooting = async (
  token: string,
  shooting_id: number,
  tech_id: number,
  force_assigned: boolean,
) => {
  try {
    const config = { headers: { token } };
    const params = { shooting_id, tech_id, force_assigned };
    const res = await axios.put(`${getAPIURL()}/assignProdShooting`, params, config);
    return res;
  } catch (error) {
    console.log('[assignProdShooting] error: ', error);
  }
};

export const changeShootingBillable = async (
  token: string,
  shooting_id: number,
  billable: boolean,
) => {
  try {
    const config = { headers: { token } };
    const params = { shootingId: shooting_id, billable };
    const res = await axios.put(`${getAPIURL()}/billShooting`, params, config);
    return res;
  } catch (error) {
    console.log('[billShooting] error: ', error);
  }
};

export const reinitializeShooting = async (token: string, shooting_id: number) => {
  try {
    const config = { headers: { token } };
    const params = { shooting_id };
    const res = await axios.post(`${getAPIURL()}/attemptShootingFlagRemoval`, params, config);
    return res;
  } catch (error) {
    console.log('[attemptShootingFlagRemoval] error: ', error);
  }
};
